import React from 'react'
import { Icons } from '../assets/imageIndex'
import ProcessSection from './ProcessSection'

const DataAnalystic = () => {
    return (
        <div>
            <section
                className="relative px-4 md:px-0 team bg-cover bg-center section-padding bg-opacity-100 py-16"
                style={{ backgroundImage: `url(${Icons.stepImg})` }}
            >
                <div className="full-width mx-auto">
                    <div className="mb-8 text-center">
                        <h1 className="text-4xl font-bold">Data Analytics Services</h1>
                        <div className="border-t-2 border-gray-300 w-16 mx-auto mt-4"></div>
                    </div>

                    <p className="text-gray-600 text-left">
                        At Covantis Sarvagis Technologies, we prioritize data-driven 
                        decision-making to empower your business. Our data analytics services help you uncover valuable insights from your data, enabling you to make informed choices that enhance performance and drive growth. We utilize advanced analytics techniques to help you understand customer behavior, market trends, and operational efficiency, ensuring your organization stays ahead in the competitive landscape.
                    </p>
                </div>
            </section>

            <section className="py-16 px-4 md:px-0 bg-gray-50">
                <div className="container mx-auto">
                    <div className="text-center mb-10" data-aos="fade-up" data-aos-duration="1500">
                        <h2 className="text-3xl font-semibold">Data Analytics Services from Our Experts</h2>
                        <p className="mt-4 text-gray-600">
                            We are a reputable data analytics services company that combines innovative strategies with advanced analytics to deliver actionable insights.
                        </p>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                         <div className="bg-white p-6 shadow-md rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Descriptive Analytics</h5>
                            <p className="mt-2 text-gray-600">
                                We analyze historical data to understand trends and patterns, providing insights into what has happened in your business. This helps in making informed strategic decisions.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-md rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Predictive Analytics</h5>
                            <p className="mt-2 text-gray-600">
                                Utilizing statistical algorithms and machine learning techniques, we forecast future outcomes based on historical data, enabling you to anticipate trends and optimize strategies.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-md rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Prescriptive Analytics</h5>
                            <p className="mt-2 text-gray-600">
                                We provide recommendations for actions you can take to achieve desired outcomes, helping you make the best decisions based on data-driven insights.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-md rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Data Visualization</h5>
                            <p className="mt-2 text-gray-600">
                                Our team specializes in creating intuitive visual representations of your data, making complex information easily understandable and actionable for stakeholders.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-md rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Data Mining</h5>
                            <p className="mt-2 text-gray-600">
                                We extract useful information from large datasets to discover patterns, correlations, and insights that can inform business strategies and decisions.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-md rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Real-time Analytics</h5>
                            <p className="mt-2 text-gray-600">
                                Our real-time analytics solutions help you monitor data as it happens, enabling quick responses to changing business conditions and customer behaviors.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <ProcessSection />
        </div>
    )
}

export default DataAnalystic