import React from 'react';
import { Icons } from '../assets/imageIndex';
import { Link } from 'react-router-dom';

const LetsTalk = () => {
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  return (
    <section
      className="lets-talk bg-cover bg-center bg-fixed" 
      style={{ backgroundImage: `url(${Icons.latImg})` }}
    >
 
      <div className=" py-20">
        <div className="w-full mx-auto text-center">
          <h6 className="text-white text-lg font-semibold mb-2">We develop digital future</h6>
          <h2 className="text-black mb-6 text-4xl font-bold">
           Let's Talk?
          </h2>
          <a
            href="tel:+911234567890"
            className="btn-1 inline-flex items-center border-0 text-white bg-gray-500 hover:bg-gray-600 px-6 py-3 rounded mr-4 mb-6"
          >
            +91 123-456-7890
          </a>
          <Link onClick={handleScrollToTop}
            to="/contact"
            className="btn-5 inline-flex items-center border-0 text-white bg-gray-600 hover:bg-gray-700 px-6 py-3 rounded"
          >
            Make an Enquiry
          </Link>
        </div>
      </div>
    </section>
  );
};

export default LetsTalk;
