import React from 'react';
import { Icons } from '../assets/imageIndex';
import ExpertDetail from './ExpertDetail';

const MERNStackDevelopment = () => {
    return (
        <div>
            <section
                className="relative px-4 md:px-0 team bg-cover bg-center section-padding bg-opacity-100 py-16"
                style={{ backgroundImage: `url(${Icons.stepImg})` }}
            >
                <div className="full-width mx-auto">
                    <div className="mb-8 text-center">
                        <h1 className="text-4xl font-bold">MERN Stack Development Services</h1>
                        <div className="border-t-2 border-gray-300 w-16 mx-auto mt-4"></div>
                    </div>

                    <p className="text-gray-600 text-left">
                        Hire skilled MERN stack developers from Covantis Sarvagis to create dynamic and responsive web applications. The MERN stack—comprising MongoDB, Express.js, React.js, and Node.js—provides a powerful framework for building full-stack applications with a seamless workflow and efficient data handling.

                        Our client-centric approach ensures that your project aligns perfectly with your business goals, while our commitment to quality guarantees a smooth development process from start to finish. 
                    </p>
                </div>
            </section>

            <section className="py-16 px-4 md:px-0 bg-gray-50">
                <div className="full-width mx-auto">
                    <div className="text-center mb-10" data-aos="fade-up" data-aos-duration="1500">
                        <h2 className="text-3xl font-semibold">Why Hire MERN Stack Developers From Covantis Sarvagis</h2>
                        <p className="mt-4 text-gray-700">
                            Our dedicated MERN stack developers are experts in utilizing the latest technologies to deliver efficient and high-quality applications tailored to your needs. We leverage agile methodologies to enhance collaboration and responsiveness throughout the development lifecycle.
                        </p>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Full-Stack Expertise</h5>
                            <p className="mt-2 text-gray-600">
                                Our developers are proficient in both front-end and back-end technologies, ensuring a smooth integration and user experience.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Rapid Development</h5>
                            <p className="mt-2 text-gray-600">
                                Using the MERN stack allows for faster development cycles, enabling quicker time-to-market for your applications.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Scalability</h5>
                            <p className="mt-2 text-gray-600">
                                Applications built on the MERN stack are highly scalable, allowing for growth as your business needs evolve.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Strong Community Support</h5>
                            <p className="mt-2 text-gray-600">
                                The MERN stack has a robust community, providing a wealth of resources and libraries to enhance development efficiency.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <ExpertDetail title={'MERN Stack'} />
        </div>
    );
}

export default MERNStackDevelopment;
