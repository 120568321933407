import React, { memo } from 'react';
import { Icons } from '../assets/imageIndex';

const AboutSection = () => {
  return (
    <section className="about-section-6 pt-20 mx-5 md:mx-0 ">
      <div className="full-width mx-auto">
        <div className="flex flex-wrap">
            <img data-aos="fade-up" data-aos-duration="1500" src={Icons.promoImg} alt="about" className="w-full lg:w-1/2 mb-2 md:mb-0" />
     
          <div className="w-full lg:w-1/2">
            <div className="about-content md:mx-8 fade-wrapper">
              <div className="section-heading mb-6">
                <h4 className="sub-heading text-left after-none opacity-100" data-text-animation="fade-in" data-duration="1.5">
                  About Our Company
                </h4>
                <h2 className="section-title opacity-100 text-left">
                  Empowering Global Clients Through Innovative Solutions
                </h2>
                <p className="fade-top text-left opacity-100">
                  At Covantis Sarvagis Technologies Private Limited, we are dedicated to delivering cutting-edge technological solutions that drive transformation. As a dynamic player in the tech industry, we focus on building impactful software, web, and digital products for local and global markets. Our goal is to foster innovation, excellence, and long-term partnerships that enable businesses to thrive in an ever-changing digital world.
                </p>
              </div>
              <div className="flex flex-wrap about-card-wrap">
                <div className="w-full md:w-1/2 mb-6">
                  <div className="about-card ali fade-top opacity-100">
                    <div className="icon mb-3 text-left ">
                      <img src={Icons.webDesign} className='w-14' alt='World-Class Web Solutions'/>
                    </div>
                    <h3 className="title text-lg font-semibold text-left">World-Class Web Solutions</h3>
                    <p className="text-left">
                      From responsive web design to custom application development, we provide end-to-end solutions that align with your business goals. We create powerful, user-friendly platforms to give your brand the online presence it deserves.
                    </p>
                  </div>
                </div>
                <div className="w-full md:w-1/2 mb-6">
                  <div className="about-card fade-top opacity-100">
                    <div className="icon mb-3 text-left">
                      <img src={Icons.teamIcon} className='w-14' alt='Highly Skilled Team' />
                    </div>
                    <h3 className="title text-lg font-semibold text-left">Highly Skilled Team</h3>
                    <p className="text-left">
                      Our team of experienced professionals is passionate about solving complex business challenges. With expertise across multiple domains, we work collaboratively to deliver solutions that are both innovative and practical.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default memo(AboutSection);
