import { Icons } from './assets/imageIndex';

export const features = [
  {
      icon: Icons.experience,
      title: 'Top Expertise',
      description:
          'Our team consists of industry-leading experts with deep knowledge in technology and innovation, ensuring that every project is handled with top-tier skills and professionalism. We provide solutions that are both cutting-edge and reliable, tailored to your business needs.',
  },
  {
      icon: Icons.qualityAssurance,
      title: 'Quality Management',
      description:
          'We uphold the highest standards of quality in every stage of the project, from planning to delivery. Our rigorous quality assurance process ensures that your project is error-free and performs optimally, meeting all your expectations.',
  },
  {
      icon: Icons.onlineCommunication,
      title: 'Utmost Flexibility',
      description:
          'We understand the dynamic nature of today’s business environment. Our team offers flexible solutions, adapting swiftly to changing project requirements and business goals while maintaining clear and effective communication throughout.',
  },
  {
      icon: Icons.guaranteed,
      title: 'Agility',
      description:
          'Our agile methodology enables us to respond quickly to feedback and evolving project needs, ensuring fast delivery without compromising quality. We continuously improve our processes to provide timely, effective solutions that meet your deadlines.',
  },
  {
      icon: Icons.digitalServices,
      title: 'Innovation',
      description:
          'At the heart of our services is innovation. We leverage the latest technologies and creative strategies to deliver solutions that not only meet today’s demands but also prepare you for tomorrow’s challenges. Our goal is to empower your business with forward-thinking ideas.',
  },
];


export const technologiesData = [
    "Android",
    "React Native",
    "Ruby on Rails",
    "Flutter",
    "Java",
    "MERN Stack",
    "Node Js",
    "Blockchain",
    "React Js",
    "Angular",
    "WordPress",
    "Magento",
    "Python",
    "Artificial Intelligence",
];

export const expData =[
    {
      img: Icons.businessIntelligence,
      text: 'Strong knowledge of software development principles.'
    },
    {
      img: Icons.qualityAssurance,
      text: 'Ability to solve issues in a given time duration.'
    },
    {
      img: Icons.digitalServices,
      text: 'Expertise in SaaS and mobile application development.'
    },
    {
      img: Icons.workflow,
      text: 'Problem-solving and creative skills.'
    },
    {
      img: Icons.teamIcon,
      text: 'Ability to communicate hassle-free.'
    },
    {
      img: Icons.experience,
      text: 'Rich experience in using agile development methodologies.'
    },
  ]