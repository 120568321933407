import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import { Icons } from '../assets/imageIndex';

const Carousel = () => {
  const slides = [
    { image: Icons.slide11, text: 'Web', description: 'Development' },
    { image: Icons.slide22, text: 'App', description: 'Development' },
    { image: Icons.slide33, text: 'Digital', description: 'Marketing' },
    { image: Icons.slide44, text: 'E-Commerce', description: 'and CMS' },
  ];


  const NextArrow = ({ onClick }) => {
    return (
      <div 
        className="absolute z-10 bannerSliderButton right-0 transform -translate-y-1/2 bg-black bg-opacity-50 text-white h-12 w-12   pb-1  content-center rounded-full hover:bg-opacity-75 mr-2 md:mr-5"
        onClick={onClick}
      >
        <FaAngleRight />
      </div>
    );
  };


  const PrevArrow = ({ onClick }) => {
    return (
      <div 
        className="absolute z-10 bannerSliderButton left-0 transform -translate-y-1/2 bg-black bg-opacity-50 text-white h-12 w-12   pb-1  content-center rounded-full hover:bg-opacity-75 ml-2 md:ml-5"
        onClick={onClick}
      >
        <FaAngleLeft />
      </div>
    );
  };

  const settings = {
    dots: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className='h-[300px] sm:h-[400px] md:h-[500px] lg:h-[600px] overflow-hidden w-full relative'>
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div
            key={index}
            className="bg-fixed bg-cover relative h-[300px] sm:h-[400px] md:h-[500px] lg:h-[600px] w-full"
          >
            {/* Image Tag for Background */}
            <img
              src={slide.image}
              alt={slide.text}
              className="object-cover w-full h-full"
            />
            {/* Overlay */}
            <div className="absolute inset-0 bg-black opacity-50"></div>

            {/* Text with fade-in effect */}
            <p className="absolute banner-text banner-top-set left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-10/12 text-center md:text-left text-white font-bold px-4 py-2 rounded-md">
              {slide.text}
            </p>
            <p className="absolute mt-12 md:mt-24 banner-discription-set-d banner-discription left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-10/12 text-center md:text-left text-white font-bold px-4 py-2 rounded-md">
              {slide.description}
            </p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
