import './App.css';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import HomeScreen from './screen/HomeScreen';
import AboutScreen from './screen/AboutScreen';
import ServicesScreen from './screen/ServicesScreen';
import ContactScreen from './screen/ContactScreen';
import Header from './component/Layout/Header';
import Footer from './component/Layout/Footer'
import LetsTalk from './component/LetsTalk';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ServiceDetail from './screen/ServiceDetail';
import ScrollButton from './component/ScrollButton';
import PrivacyPolicy from './screen/PrivacyPolicy';
import TermsConditions from './screen/TermsConditions';
function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={
            <HomeScreen />
          } />
          <Route path="/about" element={
            <AboutScreen />
          } />
          <Route path="/services" element={
            <ServicesScreen />
          } />
          <Route path="/contact" element={
            <ContactScreen />
          } />
            <Route path="/privacyPolicy" element={
            <PrivacyPolicy />
          } />
          <Route path="/termsConditions" element={
            <TermsConditions />
          } />
          <Route path="/serviceDetail/:serviceName" element={<ServiceDetail />} />
          <Route path="/" element={<LetsTalk />} />

        </Routes>
        <ScrollButton />
        <Footer />
      </div>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
