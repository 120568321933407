import React from 'react';
import { Icons } from '../assets/imageIndex';
import ExpertDetail from './ExpertDetail';

const BlockchainDevelopment = () => {
    return (
        <div>
            <section
                className="relative px-4 md:px-0 team bg-cover bg-center section-padding bg-opacity-100 py-16"
                style={{ backgroundImage: `url(${Icons.stepImg})` }}
            >
                <div className="full-width mx-auto">
                    <div className="mb-8 text-center">
                        <h1 className="text-4xl font-bold">Blockchain Development Services</h1>
                        <div className="border-t-2 border-gray-300 w-16 mx-auto mt-4"></div>
                    </div>

                    <p className="text-gray-600 text-left">
                        Unlock the potential of decentralized technology with our expert Blockchain development services at Covantis Sarvagis. We specialize in building secure and scalable blockchain solutions tailored to your business needs, leveraging smart contracts and distributed ledger technology.

                        Our experienced developers are well-versed in various blockchain platforms, ensuring that your project is aligned with the latest industry standards and best practices for maximum efficiency and security.
                    </p>
                </div>
            </section>

            <section className="py-16 px-4 md:px-0 bg-gray-50">
                <div className="full-width mx-auto">
                    <div className="text-center mb-10" data-aos="fade-up" data-aos-duration="1500">
                        <h2 className="text-3xl font-semibold">Why Hire Blockchain Developers From Covantis Sarvagis</h2>
                        <p className="mt-4 text-gray-700">
                            Our dedicated blockchain developers possess in-depth knowledge of decentralized systems and are committed to delivering innovative solutions that drive business growth. We adopt agile methodologies to ensure adaptability and collaboration throughout the development process.
                        </p>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Decentralization</h5>
                            <p className="mt-2 text-gray-600">
                                Eliminate single points of failure by leveraging the decentralized nature of blockchain technology.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Enhanced Security</h5>
                            <p className="mt-2 text-gray-600">
                                Blockchain's cryptographic algorithms provide robust security, ensuring data integrity and confidentiality.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Smart Contracts</h5>
                            <p className="mt-2 text-gray-600">
                                Automate and streamline processes using self-executing smart contracts that eliminate intermediaries.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Innovative Solutions</h5>
                            <p className="mt-2 text-gray-600">
                                Our team stays at the forefront of technology to deliver cutting-edge solutions tailored to your industry.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <ExpertDetail title={'Blockchain'} />
        </div>
    );
}

export default BlockchainDevelopment;
