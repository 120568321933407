import React from 'react';
import { Icons } from '../assets/imageIndex';
import ExpertDetail from './ExpertDetail';

const PythonDevelopment = () => {
    return (
        <div>
            <section
                className="relative px-4 md:px-0 team bg-cover bg-center section-padding bg-opacity-100 py-16"
                style={{ backgroundImage: `url(${Icons.stepImg})` }}
            >
                <div className="full-width mx-auto">
                    <div className="mb-8 text-center">
                        <h1 className="text-4xl font-bold">Python Development Services</h1>
                        <div className="border-t-2 border-gray-300 w-16 mx-auto mt-4"></div>
                    </div>

                    <p className="text-gray-600 text-left">
                        Unlock the potential of your projects with our Python development services at Covantis Sarvagis. Our skilled Python developers excel in building robust, scalable applications using frameworks like Django and Flask, ensuring high performance and security.

                        Whether you're looking to develop web applications, data analytics solutions, or automation scripts, we tailor our services to meet your unique business needs.
                    </p>
                </div>
            </section>

            <section className="py-16 px-4 md:px-0 bg-gray-50">
                <div className="full-width mx-auto">
                    <div className="text-center mb-10" data-aos="fade-up" data-aos-duration="1500">
                        <h2 className="text-3xl font-semibold">Why Hire Python Developers From Covantis Sarvagis</h2>
                        <p className="mt-4 text-gray-700">
                            Our dedicated Python developers have a wealth of experience in delivering high-quality, efficient applications. We prioritize best practices and quality assurance in every project, ensuring your solutions are not only functional but also maintainable.
                        </p>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Rapid Development</h5>
                            <p className="mt-2 text-gray-600">
                                Our agile approach and expertise in Python allow for fast and efficient development cycles.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Data Science and Analytics</h5>
                            <p className="mt-2 text-gray-600">
                                Leverage Python’s powerful libraries for data analysis, machine learning, and visualization.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Web Development</h5>
                            <p className="mt-2 text-gray-600">
                                Build scalable and secure web applications with frameworks like Django and Flask.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Versatile Integration</h5>
                            <p className="mt-2 text-gray-600">
                                Easily integrate with various platforms and services using Python’s extensive libraries.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <ExpertDetail title={'Python'} />
        </div>
    );
}

export default PythonDevelopment;
