import React from 'react';
import { Icons } from '../assets/imageIndex';
import ExpertDetail from './ExpertDetail';

const MagentoDevelopment = () => {
    return (
        <div>
            <section
                className="relative px-4 md:px-0 team bg-cover bg-center section-padding bg-opacity-100 py-16"
                style={{ backgroundImage: `url(${Icons.stepImg})` }}
            >
                <div className="full-width mx-auto">
                    <div className="mb-8 text-center">
                        <h1 className="text-4xl font-bold">Magento Development Services</h1>
                        <div className="border-t-2 border-gray-300 w-16 mx-auto mt-4"></div>
                    </div>

                    <p className="text-gray-600 text-left">
                        Elevate your e-commerce platform with our expert Magento development services at Covantis Sarvagis. Our skilled Magento developers create robust, scalable, and customizable online stores that cater to your business needs.

                        From custom theme development to integration of payment gateways and third-party services, we ensure your Magento store is optimized for performance, security, and user experience.
                    </p>
                </div>
            </section>

            <section className="py-16 px-4 md:px-0 bg-gray-50">
                <div className="full-width mx-auto">
                    <div className="text-center mb-10" data-aos="fade-up" data-aos-duration="1500">
                        <h2 className="text-3xl font-semibold">Why Hire Magento Developers From Covantis Sarvagis</h2>
                        <p className="mt-4 text-gray-700">
                            Our dedicated Magento developers have extensive experience in e-commerce solutions, ensuring your store is equipped with the latest features and functionalities. We prioritize quality and customer satisfaction in every project.
                        </p>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Custom E-commerce Solutions</h5>
                            <p className="mt-2 text-gray-600">
                                Tailored solutions to fit your unique business model and customer expectations.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">SEO Optimization</h5>
                            <p className="mt-2 text-gray-600">
                                Optimize your online store for search engines to drive more traffic and increase sales.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Responsive Design</h5>
                            <p className="mt-2 text-gray-600">
                                Ensure your e-commerce site is fully responsive and delivers a great user experience on all devices.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Ongoing Support and Maintenance</h5>
                            <p className="mt-2 text-gray-600">
                                Continuous support to ensure your Magento store remains secure and up-to-date.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <ExpertDetail title={'Magento'} />
        </div>
    );
}

export default MagentoDevelopment;
