import React from 'react';
import { Icons } from '../assets/imageIndex';
import ProcessSection from './ProcessSection';

const AIDevelopment = () => {
    return (
        <div>
                  <section
                className="relative px-4 md:px-0 team bg-cover bg-center section-padding bg-opacity-100 py-16 "
                style={{ backgroundImage: `url(${Icons.stepImg})` }}
            >
                <div className="full-width mx-auto">
                    <div className="mb-8 text-center">
                        <h1 className="text-4xl font-bold">AI Development Services</h1>
                        <div className="border-t-2 border-gray-300 w-16 mx-auto mt-4"></div>
                    </div>

                    <p className="text-gray-600 text-left">
                    At Covantis Sarvagis Technologies, we harness the power of
                     artificial intelligence to create innovative solutions 
                     that drive efficiency and enhance user experiences. Our AI development services include everything from machine learning algorithms to natural language processing, ensuring your business stays ahead in a rapidly evolving technological landscape.
                    </p>
                </div>
            </section>

            <section className="py-16 px-4 md:px-0 bg-gray-50">
                <div className="container mx-auto">
                    <div className="text-center mb-10" data-aos="fade-up" data-aos-duration="1500">
                        <h2 className="text-3xl font-semibold">AI Development Services Tailored for Your Business</h2>
                        <p className="mt-4 text-gray-700">
                            We are a leading AI development company committed to delivering advanced solutions that enhance business operations and user engagement.
                        </p>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Machine Learning Solutions</h5>
                            <p className="mt-2 text-gray-600">
                                We develop custom machine learning models to help your business automate processes, predict outcomes, and make data-driven decisions.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Natural Language Processing</h5>
                            <p className="mt-2 text-gray-600">
                                Our NLP solutions enhance user interactions through chatbots, sentiment analysis, and language translation, providing seamless communication.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Computer Vision Development</h5>
                            <p className="mt-2 text-gray-600">
                                We create advanced computer vision applications that enable image recognition, object detection, and visual analysis for various industries.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">AI-Powered Analytics</h5>
                            <p className="mt-2 text-gray-600">
                                Our AI analytics solutions provide deep insights into user behavior and trends, empowering you to make informed business decisions.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Robotic Process Automation</h5>
                            <p className="mt-2 text-gray-600">
                                We implement RPA to streamline repetitive tasks, improve accuracy, and increase efficiency across your business operations.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Custom AI Solutions</h5>
                            <p className="mt-2 text-gray-600">
                                Our team works closely with you to develop custom AI solutions that fit your specific business needs and objectives.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <ProcessSection />
        </div>
    );
}

export default AIDevelopment;
