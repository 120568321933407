import React from 'react';
import { expData } from '../constant';
import { Icons } from '../assets/imageIndex';

const ExpertDetail = ({ title }) => {
  return (
    <section
      className="relative px-4 md:px-0 team bg-cover bg-center section-padding bg-opacity-100 py-16 "
      style={{ backgroundImage: `url(${Icons.latImg})` }}
    >
      <div className="full-width mx-auto">
        <div className="text-center mb-8">
          <h3 className="text-3xl font-semibold">Expertise of Our {title} Developer</h3>
          <p className="text-gray-600">
            Our {title} developers are knowledgeable, professional, and able to complete all project needs early or on schedule.
          </p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {expData.map((expertise, index) => (

            <div data-aos="fade-up" data-aos-duration="1500" key={index} className="bg-white p-6 h-44 shadow-md rounded-md text-center">
              <div className="mb-4">
                <img src={expertise.img} className="w-16 mx-auto" alt="Expertise Icon" />
              </div>
              <p className="text-gray-700">{expertise.text}</p>
            </div>

          ))}
        </div>
      </div>
    </section>
  );
};

export default ExpertDetail;
