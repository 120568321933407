import logoLight from './images/logo-light.png';
import sliderImg1 from './images/01.jpg';
import sliderImg2 from './images/02.jpg';
import sliderImg3 from './images/03.jpg';
import sliderImg4 from './images/04.jpg';
import sliderImg5 from './images/05.jpg';
import sliderImg6 from './images/06.jpg';
import workImg1 from './images/1.jpg';
import workImg2 from './images/2.jpg';
import workImg3 from './images/3.jpg';
import promoImg from './images/6.jpg';
import latestpromoImg1 from './images/01_1.jpg';
import latestpromoImg2 from './images/02_1.jpg';
import latestpromoImg3 from './images/03_1.jpg';
import slide11 from './images/slide11.png';
import slide22 from './images/slide22.png';
import slide33 from './images/slide33.jpeg';
import slide44 from './images/slide44.png';
import bannerImg from './images/bannerImg.jpeg';
import stepImg from './images/stepImg.png';
import bgCoverImg from './images/bgCoverImg.png';
import logoWhite from './images/logo-white.png';
import webDesign from './icons/webDesign.svg';
import teamIcon from './icons/teamIcon.svg';
import contentWriting from './icons/contentWriting.svg';
import digitalMarketing from './icons/digitalMarketing.svg';
import digitalServices from './icons/digitalServices.svg';
import digitalStrategy from './icons/digitalStrategy.svg';
import onlineCommunication from './icons/onlineCommunication.svg';
import onlineStore from './icons/onlineStore.svg';
import guaranteed from './icons/guaranteed.svg';
import qualityAssurance from './icons/qualityAssurance.svg';
import workflow from './icons/workflow.svg';
import success from './icons/success.svg';
import experience from './icons/experience.svg';
import premiumQuality from './icons/premiumQuality.svg';
import latImg from './images/latImg.jpeg';
import contentWritingGray from './icons/contentWritingGray.svg';
import digitalMarketingGray from './icons/digitalMarketingGray.svg';
import digitalServicesGray from './icons/digitalServicesGray.svg';
import digitalStrategyGray from './icons/digitalStrategyGray.svg';
import onlineCommunicationGray from './icons/onlineCommunicationGray.svg';
import onlineStoreGray from './icons/onlineStoreGray.svg';
import python from './icons/python.png';
import php from './icons/php.png';
import android from './icons/android.png';
import apple from './icons/apple.png';
import blockchainTechnology from './icons/blockchain-technology.png';
import document from './icons/document.png';
import magento from './icons/magento.png';
import nodeJs from './icons/node-js.png';
import physics from './icons/physics.png';
import programming from './icons/programming.png';
import wordpress from './icons/wordpress.png';
import cloudServices from './icons/cloudServices.svg';
import cloudServicesGray from './icons/cloudServicesGray.svg';
import aiDevelopment from './icons/aiDevelopment.svg';
import aiDevelopmentGray from './icons/aiDevelopmentGray.svg';
import dataVisualization from './icons/dataVisualization.svg';
import dataVisualizationGray from './icons/dataVisualizationGray.svg';
import businessIntelligence from './icons/businessIntelligence.svg';
import businessIntelligenceGray from './icons/businessIntelligenceGray.svg';
import devops from './icons/devops.svg';
import devopsGray from './icons/devopsGray.svg';
import digitalBlack from './icons/digitalBlack.svg';
import digitalBlackGray from './icons/digitalBlackGray.svg';
import itSupport from './icons/itSupport.svg';
import itSupportGray from './icons/itSupportGray.svg';
import cyber from './icons/cyber.svg';
import cyberGray from './icons/cyberGray.svg';
import eCom from './icons/eCom.svg';
import eComGray from './icons/eComGray.svg';
import mobiImg1 from './images/mobi1.png';
import mobiImg2 from './images/mobi2.png';
import Works1 from './images/Works1.png';
import Works2 from './images/works2.png';
import Works3 from './images/works3.png';
import Works4 from './images/works4.png';
import Works5 from './images/works5.png';
import faqImage from './images/faq.png';


export const Icons = {
  faqImage,
  devops,
  devopsGray,
  digitalBlack,
  digitalBlackGray,
  itSupport,
  itSupportGray,
  cyber,
  cyberGray,
  eCom,
  eComGray,
  Works1,
  Works2,
  Works3,
  Works4,
  Works5,
  bannerImg,
  mobiImg2,
  mobiImg1,
  cloudServices,
  cloudServicesGray,
  aiDevelopment,
  aiDevelopmentGray,
  dataVisualization,
  dataVisualizationGray,
  businessIntelligence,
  businessIntelligenceGray,
  wordpress,
  python,
  php,
  android,
  apple,
  blockchainTechnology,
  document,
  magento,
  nodeJs,
  physics,
  programming,
  contentWritingGray,
  digitalMarketingGray,
  digitalStrategyGray,
  digitalServicesGray,
  onlineCommunicationGray,
  onlineStoreGray,
  latImg,
  success,
  experience,
  premiumQuality,
  guaranteed,
  workflow,
  qualityAssurance,
  digitalMarketing,
  digitalServices,
  digitalStrategy,
  onlineCommunication,
  onlineStore,
  contentWriting,
  teamIcon,
  webDesign,
  logoWhite,
  bgCoverImg,
  stepImg,
  slide11,
  slide22,
  slide33,
  slide44,
  sliderImg1,
  logoLight,
  sliderImg2,
  sliderImg3,
  sliderImg4,
  sliderImg5,
  sliderImg6,
  workImg3,
  workImg1,
  workImg2,
  promoImg,
  latestpromoImg1,
  latestpromoImg2,
  latestpromoImg3,
};