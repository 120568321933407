import React from 'react'
import { Icons } from '../assets/imageIndex'
import ProcessSection from './ProcessSection'

const CustomSoftware = () => {
    return (
        <div>
            <section
                className="relative px-4 md:px-0 team bg-cover bg-center section-padding bg-opacity-100 py-16"
                style={{ backgroundImage: `url(${Icons.stepImg})` }}
            >
                <div className="full-width mx-auto">
                    <div className="mb-8 text-center">
                        <h1 className="text-4xl font-bold">Custom Software Development Services</h1>
                        <div className="border-t-2 border-gray-300 w-16 mx-auto mt-4"></div>
                    </div>

                    <p className="text-gray-600 text-left">
                        At Covantis Sarvagis Technologies, we specialize in providing tailored software solutions that meet the unique needs of your business. Our custom software development services are designed to streamline processes, enhance productivity, and drive innovation. We collaborate closely with you to understand your requirements, ensuring that our solutions are perfectly aligned with your goals.
                    </p>
                </div>
            </section>

            <section className="py-16 px-4 md:px-0 bg-gray-50">
                <div className="container mx-auto">
                    <div className="text-center mb-10" data-aos="fade-up" data-aos-duration="1500">
                        <h2 className="text-3xl font-semibold">Custom Software Development Services from Our Experts</h2>
                        <p className="mt-4 text-gray-600">
                            We are a reputable custom software development company dedicated to creating innovative solutions tailored to your business needs.
                        </p>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                         <div className="bg-white p-6 shadow-md rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Custom Application Development</h5>
                            <p className="mt-2 text-gray-600">
                                We design and develop custom applications that cater specifically to your business requirements, ensuring optimal performance and user experience.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-md rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Enterprise Software Solutions</h5>
                            <p className="mt-2 text-gray-600">
                                Our team creates robust enterprise software solutions that streamline operations, improve productivity, and facilitate communication across your organization.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-md rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Web Application Development</h5>
                            <p className="mt-2 text-gray-600">
                                We develop scalable web applications that are tailored to your business needs, providing seamless functionality and an exceptional user experience.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-md rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Mobile Application Development</h5>
                            <p className="mt-2 text-gray-600">
                                Our mobile app development services focus on creating user-friendly and feature-rich applications for both Android and iOS platforms.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-md rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Software Integration</h5>
                            <p className="mt-2 text-gray-600">
                                We ensure seamless integration of your existing software systems with new applications, enabling efficient data flow and operational harmony.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-md rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Maintenance and Support</h5>
                            <p className="mt-2 text-gray-600">
                                Our commitment to you extends beyond development; we offer ongoing maintenance and support to ensure your software runs smoothly and remains up-to-date.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <ProcessSection />
        </div>
    )
}

export default CustomSoftware