import React from 'react';
import { Icons } from '../assets/imageIndex';
import ProcessSection from './ProcessSection';

const DigitalMarketingServices = () => {
    return (
        <div>
            <section
                className="relative px-4 md:px-0 team bg-cover bg-center section-padding bg-opacity-100 py-16"
                style={{ backgroundImage: `url(${Icons.stepImg})` }}
            >
                <div className="full-width mx-auto">
                    <div className="mb-8 text-center">
                        <h1 className="text-4xl font-bold">Digital Marketing Services</h1>
                        <div className="border-t-2 border-gray-300 w-16 mx-auto mt-4"></div>
                    </div>

                    <p className="text-gray-600 text-left">
                        At Covantis Sarvagis Technologies, we specialize in crafting comprehensive digital marketing strategies that drive engagement, enhance brand awareness, and deliver measurable results. Our expert team utilizes the latest tools and techniques to ensure your business thrives in the digital landscape.
                    </p>
                </div>
            </section>

            <section className="py-16 px-4 md:px-0 bg-gray-50">
                <div className="container mx-auto">
                    <div className="text-center mb-10" data-aos="fade-up" data-aos-duration="1500">
                        <h2 className="text-3xl font-semibold">Comprehensive Digital Marketing Solutions for Your Business</h2>
                        <p className="mt-4 text-gray-700">
                            Our digital marketing services are designed to meet the unique needs of your business, enabling you to connect with your audience and achieve your marketing goals.
                        </p>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Search Engine Optimization (SEO)</h5>
                            <p className="mt-2 text-gray-600">
                                Our SEO experts optimize your website and content to improve visibility on search engines, driving organic traffic and increasing conversions.
                            </p>
                        </div>
                        <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Pay-Per-Click Advertising (PPC)</h5>
                            <p className="mt-2 text-gray-600">
                                We create targeted PPC campaigns that maximize your ROI, bringing immediate traffic to your website and generating leads through platforms like Google Ads and social media.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Social Media Marketing</h5>
                            <p className="mt-2 text-gray-600">
                                Our social media strategies engage your audience across platforms, building brand loyalty and driving traffic through compelling content and targeted ads.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Content Marketing</h5>
                            <p className="mt-2 text-gray-600">
                                We develop and distribute valuable content that attracts and engages your target audience, establishing your brand as an industry authority.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Email Marketing</h5>
                            <p className="mt-2 text-gray-600">
                                Our email marketing campaigns are designed to nurture leads and maintain customer relationships, utilizing personalized content to drive conversions.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Analytics and Reporting</h5>
                            <p className="mt-2 text-gray-600">
                                We provide detailed analytics and reporting on your marketing efforts, allowing you to track performance, understand customer behavior, and optimize strategies.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <ProcessSection />
        </div>
    );
}

export default DigitalMarketingServices;
