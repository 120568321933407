import React from 'react';
import { Icons } from '../assets/imageIndex';
import ExpertDetail from './ExpertDetail';

const ArtificialIntelligenceDevelopment = () => {
    return (
        <div>
            <section
                className="relative px-4 md:px-0 team bg-cover bg-center section-padding bg-opacity-100 py-16"
                style={{ backgroundImage: `url(${Icons.stepImg})` }}
            >
                <div className="full-width mx-auto">
                    <div className="mb-8 text-center">
                        <h1 className="text-4xl font-bold">Artificial Intelligence Development Services</h1>
                        <div className="border-t-2 border-gray-300 w-16 mx-auto mt-4"></div>
                    </div>

                    <p className="text-gray-600 text-left">
                        Transform your business with our Artificial Intelligence development services at Covantis Sarvagis. Our experienced AI developers specialize in creating intelligent systems that can learn and adapt, providing insights and automation that drive efficiency and innovation.

                        We utilize cutting-edge frameworks and libraries, including TensorFlow and PyTorch, to deliver solutions in machine learning, natural language processing, and computer vision tailored to your specific needs.
                    </p>
                </div>
            </section>

            <section className="py-16 px-4 md:px-0 bg-gray-50">
                <div className="full-width mx-auto">
                    <div className="text-center mb-10" data-aos="fade-up" data-aos-duration="1500">
                        <h2 className="text-3xl font-semibold">Why Hire AI Developers From Covantis Sarvagis</h2>
                        <p className="mt-4 text-gray-700">
                            Our dedicated AI developers leverage advanced algorithms and technologies to build robust AI solutions that provide a competitive edge. We focus on quality, scalability, and security in every project, ensuring your AI systems are reliable and effective.
                        </p>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Enhanced Decision Making</h5>
                            <p className="mt-2 text-gray-600">
                                Utilize AI to analyze data and provide insights that support informed business decisions.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Automation of Tasks</h5>
                            <p className="mt-2 text-gray-600">
                                Implement AI-driven automation to streamline processes and reduce operational costs.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Natural Language Processing</h5>
                            <p className="mt-2 text-gray-600">
                                Build applications that understand and process human language, enhancing user interaction.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Predictive Analytics</h5>
                            <p className="mt-2 text-gray-600">
                                Harness the power of AI to predict trends and behaviors, driving proactive strategies.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <ExpertDetail title={'Artificial Intelligence'} />
        </div>
    );
}

export default ArtificialIntelligenceDevelopment;
