import React, { useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { Icons } from '../assets/imageIndex';

const FaqSection = () => {

    const [activeIndex, setActiveIndex] = useState(null);
    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const faqs = [
        {
            question: 'How long does software development take?',
            answer: 'We cannot possibly answer this question precisely because there are projects finished in 2 to 3 weeks, but there are also tasks that take several years to complete.',
        },
        {
            question: 'How much will the Covantis Sarvagis developers’ work cost?',
            answer: 'In our company, we pay our employees based on hourly rates, depending on the technology utilized and the experience level of the specialists involved in the project.',
        },
        {
            question: 'How do you guarantee the product quality?',
            answer: 'We work in a short iteration mode and regularly test the work results, taking into account all customer preferences and the target audience reaction.',
        },
        {
            question: 'Can you handle large-scale projects?',
            answer: 'Yes, we have experience in managing large-scale projects and delivering scalable solutions to meet client needs.',
        },
        {
            question: 'Do you offer post-launch support?',
            answer: 'Yes, we offer comprehensive post-launch support to ensure smooth operation and address any issues that may arise.',
        },
    ];

    return (
        <section className="px-4 md:px-0 section-padding bg-gray-50 py-12">
            <div className="full-width mx-auto">
                <div className="mb-8 text-center">
                    <h6 className="wow" data-splitting="">Some Important FAQ's</h6>
                    <h1 className="text-4xl font-bold" data-splitting="">Common Frequently Asked Questions</h1>
                    <div className="border-t-2 border-gray-300 w-16 mx-auto mt-4"></div>
                </div>
                <div className="inline md:flex text-left">
                    <div className="w-full md:w-2/3 mr-4">
                        {faqs.map((faq, index) => (
                            <div key={index} className="border m-1">
                                <button
                                    className={`flex justify-between w-full text-left px-2 py-3 bg-white`}
                                    onClick={() => toggleAccordion(index)}
                                >
                                    <p className="flex items-center mb-0 text-gray-700">
                                        {index + 1}. {faq.question}
                                    </p>
                                    <div className="ite bg-orange-500 w-7 h-7 text-center  items-center rounded-full">
                                        {activeIndex === index ? <FiChevronUp className='text-white' size={20} /> : <FiChevronDown className='text-white' size={20} />}
                                    </div>
                                </button>
                                {activeIndex === index && (
                                    <p className="px-4 pb-3 mb-0 bg-white text-gray-500 rounded-b-lg">
                                        {faq.answer}
                                    </p>
                                )}
                            </div>
                        ))}
                    </div>
                    <div className="w-full md:w-2/4 wow fadeInRight">
                        <img src={Icons.faqImage} alt="product quality" className="w-full h-auto bg-fixed rounded-lg" />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FaqSection;
