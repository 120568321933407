import React from 'react';
import { Icons } from '../assets/imageIndex';
import ExpertDetail from './ExpertDetail';

const WordPressDevelopment = () => {
    return (
        <div>
            <section
                className="relative px-4 md:px-0 team bg-cover bg-center section-padding bg-opacity-100 py-16"
                style={{ backgroundImage: `url(${Icons.stepImg})` }}
            >
                <div className="full-width mx-auto">
                    <div className="mb-8 text-center">
                        <h1 className="text-4xl font-bold">WordPress Development Services</h1>
                        <div className="border-t-2 border-gray-300 w-16 mx-auto mt-4"></div>
                    </div>

                    <p className="text-gray-600 text-left">
                        Unlock the full potential of your website with our expert WordPress development services at Covantis Sarvagis. Our skilled WordPress developers create custom themes and plugins tailored to your business needs, ensuring a unique and functional web presence.

                        Whether you're looking to build a blog, e-commerce site, or a corporate website, we deliver responsive, SEO-friendly solutions that drive engagement and conversions.
                    </p>
                </div>
            </section>

            <section className="py-16 px-4 md:px-0 bg-gray-50">
                <div className="full-width mx-auto">
                    <div className="text-center mb-10" data-aos="fade-up" data-aos-duration="1500">
                        <h2 className="text-3xl font-semibold">Why Hire WordPress Developers From Covantis Sarvagis</h2>
                        <p className="mt-4 text-gray-700">
                            Our dedicated WordPress developers possess extensive experience in creating robust and scalable websites. We follow best practices in coding and design to ensure your site is not only visually appealing but also functional and user-friendly.
                        </p>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Custom Solutions</h5>
                            <p className="mt-2 text-gray-600">
                                Get tailor-made solutions that fit your specific business requirements and branding.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">SEO Friendly</h5>
                            <p className="mt-2 text-gray-600">
                                We optimize your site for search engines, enhancing visibility and driving traffic.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Responsive Design</h5>
                            <p className="mt-2 text-gray-600">
                                Ensure your website looks great on all devices, providing an excellent user experience.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Ongoing Support</h5>
                            <p className="mt-2 text-gray-600">
                                Enjoy continuous support and maintenance to keep your website running smoothly.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <ExpertDetail title={'WordPress'} />
        </div>
    );
}

export default WordPressDevelopment;
