import React from 'react';
import { Icons } from '../assets/imageIndex';
import ProcessSection from './ProcessSection';

const BusinessIntelligence = () => {
    return (
        <div>
                  <section
                className="relative px-4 md:px-0 team bg-cover bg-center section-padding bg-opacity-100 py-16"
                style={{ backgroundImage: `url(${Icons.stepImg})` }}
            >
                <div className="full-width mx-auto">
                    <div className="mb-8 text-center">
                        <h1 className="text-4xl font-bold">Business Intelligence Services</h1>
                        <div className="border-t-2 border-gray-300 w-16 mx-auto mt-4"></div>
                    </div>

                    <p className="text-gray-600 text-left">
                        At Covantis Sarvagis Technologies, we provide comprehensive Business Intelligence
                         (BI) solutions that empower organizations to make data-driven decisions. Our team leverages advanced analytics, data visualization, and reporting tools to transform raw data into actionable insights, helping you stay competitive in today's fast-paced business environment.
                    </p>
                </div>
            </section>

            <section className="py-16 px-4 md:px-0 bg-gray-50">
                <div className="container mx-auto">
                    <div className="text-center mb-10" data-aos="fade-up" data-aos-duration="1500">
                        <h2 className="text-3xl font-semibold">Empowering Decisions with Business Intelligence</h2>
                        <p className="mt-4 text-gray-700">
                            We specialize in delivering tailored BI solutions that fit the unique needs of your business, enhancing your analytical capabilities.
                        </p>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Data Warehousing</h5>
                            <p className="mt-2 text-gray-600">
                                We design and implement robust data warehouses that centralize your data, ensuring easy access and analysis for improved decision-making.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Data Visualization</h5>
                            <p className="mt-2 text-gray-600">
                                Our visualization solutions help you present complex data in an easily digestible format, enabling quick insights and informed decisions.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Advanced Analytics</h5>
                            <p className="mt-2 text-gray-600">
                                We leverage predictive and prescriptive analytics to help you identify trends, forecast outcomes, and optimize business strategies.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Custom Reporting</h5>
                            <p className="mt-2 text-gray-600">
                                Our custom reporting solutions provide you with tailored insights that align with your specific business objectives and KPIs.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">ETL Services</h5>
                            <p className="mt-2 text-gray-600">
                                We offer Extract, Transform, Load (ETL) services to help you streamline data integration from various sources into your BI system.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Consulting Services</h5>
                            <p className="mt-2 text-gray-600">
                                Our BI consulting services guide you in developing effective strategies to leverage data analytics and improve overall performance.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <ProcessSection />
        </div>
    );
}

export default BusinessIntelligence;
