import React, { useState } from 'react';
import { Icons } from '../assets/imageIndex';
import { Link } from 'react-router-dom';

const ServicesSection = ({ title }) => {
  const [hoveredService, setHoveredService] = useState(null);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const services = [
    {
      id: 1,
      icon: Icons.digitalMarketing,
      hoverIcon: Icons.digitalMarketingGray,
      title: 'App Development',
      description: 'At Covantis Sarvagis  Technologies, we aim to bring your unique app idea to life through our end-to-end mobile app development services.',
      path: 'mobile-app-development'
    },
    {
      id: 2,
      icon: Icons.digitalServices,
      hoverIcon: Icons.digitalServicesGray,
      title: 'Web Development',
      description: 'We create web solutions that are ready to RACE (Reach, Attract, Communicate, and Engage) your audience.',
      path: 'web-development'
    },
    {
      id: 3,
      icon: Icons.aiDevelopment,
      hoverIcon: Icons.aiDevelopmentGray,
      title: 'AI Development',
      description: 'We harness the power of artificial intelligence to create innovative solutions that drive efficiency and enhance user experiences.',
      path: 'ai-development'

    },
    {
      id: 4,
      icon: Icons.onlineStore,
      hoverIcon: Icons.onlineStoreGray,
      title: 'Custom Software',
      description: 'We specialize in providing tailored software solutions that meet the unique needs of your business. ',
      path: 'custom-software-development'
    },
    {
      id: 5,
      icon: Icons.digitalStrategy,
      hoverIcon: Icons.digitalStrategyGray,
      title: 'UI/UX Design',
      description: 'One of the leading UI UX design company , prioritizes your needs and works to turn your idea into a reality in the cutthroat marketplace.',
      path: 'ui-ux-design'
    },
    {
      id: 6,
      icon: Icons.cloudServices,
      hoverIcon: Icons.cloudServicesGray,
      title: 'Cloud Services',
      description: 'We provide comprehensive cloud services that empower businesses to enhance flexibility, scalability, and security.',
      path: 'cloud-services'

    },
    {
      id: 7,
      icon: Icons.onlineCommunication,
      hoverIcon: Icons.onlineCommunicationGray,
      title: 'Social Network',
      description: 'We specialize in creating dynamic and engaging social networking platforms  tailored to your specific needs.',
      path: 'social-network-development'
    },
    {
      id: 8,
      icon: Icons.businessIntelligence,
      hoverIcon: Icons.businessIntelligenceGray,
      title: 'Business Intelligence',
      description: 'We provide comprehensive Business Intelligence (BI) solutions that empower organizations to make data-driven decisions.',
      path: 'business-intelligence'
    },
    {
      id: 9,
      icon: Icons.contentWriting,
      hoverIcon: Icons.contentWritingGray,
      title: 'Data Analytics',
      description: 'We prioritize data-driven  decision-making to empower your business.',
      path: 'data-analytics'
    },
    {
      id: 10,
      icon: Icons.eCom,
      hoverIcon: Icons.eComGray,
      title: 'E-Commerce Solutions',
      description: 'we offer cutting-edge e-commerce solutions designed to enhance your online presence and drive sales growth. ',
      path: 'e-commerce-solutions'
    },
    {
      id: 11,
      icon: Icons.itSupport,
      hoverIcon: Icons.itSupportGray,
      title: 'IT Consulting',
      description: 'we offer expert IT consulting services that empower businesses to optimize their technology infrastructure and improve operational efficiency.',
      path: 'it-consulting'
    },
    {
      id: 12,
      icon: Icons.cyber,
      hoverIcon: Icons.cyberGray,
      title: 'Cybersecurity',
      description: 'we offer advanced cybersecurity services to protect your business against ever-evolving cyber threats.',
      path: 'cybersecurity-services'
    },
    {
      id: 13,
      icon: Icons.devops,
      hoverIcon: Icons.devopsGray,
      title: 'DevOps',
      description: 'we offer comprehensive DevOps services that streamline your development and operations processes. ',
      path: 'devops-services'
    },
    {
      id: 14,
      icon: Icons.digitalBlack,
      hoverIcon: Icons.digitalBlackGray,
      title: 'Digital Marketing',
      description: 'we specialize in crafting comprehensive digital marketing strategies that drive engagement, enhance brand awareness, and deliver measurable results.',
      path: 'digital-marketing-services'
    },
  ];

  return (
    <section className="services px-4 md:px-0 section-padding">
      <div className="full-width mx-auto">
        <div className="mb-8 text-center">
          <h6 className="wow" data-splitting="">What We Do</h6>
          <h1 className="text-4xl font-bold" data-splitting="">Our Services</h1>
          <div className="border-t-2 border-gray-300 w-16 mx-auto mt-4"></div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
          {services.slice(0, title === 'home' ? 6 : services.length).map((service, index) => (
            <div data-aos={'fade-up'} data-aos-duration="1500">
              <Link
                to={`/serviceDetail/${service.path}`}
                key={service.id}
                onClick={handleScrollToTop}
                className="item bg-white p-6 shadow-lg rounded-sm flex flex-col justify-between relative"
                onMouseEnter={() => setHoveredService(service.id)}
                onMouseLeave={() => setHoveredService(null)}
              >
                <div className="wrap">
                  <div className="icon-1 text-4xl mb-4">
                    <img
                      src={hoveredService === service.id ? service.hoverIcon : service.icon}
                      alt={service.title}
                      className={`w-16 service-icon ${hoveredService === service.id ? 'hovered' : ''}`}
                    />
                  </div>
                  <div className="con">
                    <h4 className="text-xl font-medium mb-2">{service.title}</h4>
                    <p className="text-gray-600 h-24">{service.description}</p>
                    <div className="icon-2 mt-4">
                      <span className="text-primary">
                        <span className="fa-sharp fa-light fa-arrow-right"></span>
                      </span>
                    </div>
                  </div>
                  <div className="numb absolute bottom-6 right-6 text-4xl font-bold text-gray-200">
                    {index + 1 >= 10 ? `${service.id}` : `0${service.id}`}
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
