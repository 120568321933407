import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Icons } from '../assets/imageIndex';

const WorksSection = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className="works section-padding bg-drk py-20">
      <div className="container mx-auto">
        <div className="mb-12 text-center">
          <h6 className="text-gray-500">Portfolio & Projects</h6>
          <h1 className="text-4xl font-bold">Our Featured Works</h1>
          <div className="w-16 border-t-2 border-gray-300 mx-auto mt-4"></div>
        </div>
      </div>

      <div className="full-width mx-auto">
        <Slider {...settings} className="mx-4">

          <div className="item p-4">
            <div className="wrap bg-gray-950 rounded-lg overflow-hidden">
              <img src={Icons.Works5} alt="E-Commerce Platform" className="h-72 w-full" />
              <div className="text p-4">
                <h4 className="text-lg font-semibold">E-Commerce Platform</h4>
                <p className="text-gray-500">Web Development</p>
              </div>
            </div>
          </div>

          <div className="item p-4">
            <div className="wrap bg-gray-950 rounded-lg overflow-hidden">
              <img src={Icons.Works1} alt="Mobile App for Healthcare" className="h-72 w-full" />
              <div className="text p-4">
                <h4 className="text-lg font-semibold">Healthcare Mobile App</h4>
                <p className="text-gray-500">App Development</p>
              </div>
            </div>
          </div>

          <div className="item p-4">
            <div className="wrap bg-gray-950 rounded-lg overflow-hidden">
              <img src={Icons.Works4} alt="Corporate Branding" className="h-72 w-full" />
              <div className="text p-4">
                <h4 className="text-lg font-semibold">Corporate Branding</h4>
                <p className="text-gray-500">Branding & Identity</p>
              </div>
            </div>
          </div>

          <div className="item p-4">
            <div className="wrap bg-gray-950 rounded-lg overflow-hidden">
              <img src={Icons.Works3} alt="Digital Marketing Strategy" className="h-72 w-full" />
              <div className="text p-4">
                <h4 className="text-lg font-semibold">Digital Marketing Strategy</h4>
                <p className="text-gray-500">Marketing Solutions</p>
              </div>
            </div>
          </div>

        </Slider>
      </div>
    </section>
  );
};

export default WorksSection;
