import React from 'react';
import { Icons } from '../assets/imageIndex';
import ExpertDetail from './ExpertDetail';

const RubyOnRailsDevelopment = () => {
    return (
        <div>
            <section
                className="relative px-4 md:px-0 team bg-cover bg-center section-padding bg-opacity-100 py-16"
                style={{ backgroundImage: `url(${Icons.stepImg})` }}
            >
                <div className="full-width mx-auto">
                    <div className="mb-8 text-center">
                        <h1 className="text-4xl font-bold">Ruby on Rails Development Services</h1>
                        <div className="border-t-2 border-gray-300 w-16 mx-auto mt-4"></div>
                    </div>

                    <p className="text-gray-600 text-left">
                        Hire experienced Ruby on Rails developers from Covantis Sarvagis to create robust, scalable web applications. Our team leverages the power of Ruby on Rails, along with modern tools and frameworks, to build high-quality applications that meet your business needs.

                        We take a customer-centric approach, ensuring that your project aligns with your specific requirements. When you hire top Ruby on Rails developers from Covantis Sarvagis, you benefit from a flexible engagement model. Our commitment to rigorous quality checks at every stage of development is why we are recognized as a leading Ruby on Rails development company.
                    </p>
                </div>
            </section>

            <section className="py-16 px-4 md:px-0 bg-gray-50">
                <div className="full-width mx-auto">
                    <div className="text-center mb-10" data-aos="fade-up" data-aos-duration="1500">
                        <h2 className="text-3xl font-semibold">Why Hire Ruby on Rails Developers From Covantis Sarvagis</h2>
                        <p className="mt-4 text-gray-700">
                            Employ dedicated Ruby on Rails developers from Covantis Sarvagis, who are well-versed in the framework and know how to implement best practices effectively to align with your business objectives. Our expertise in agile methodologies enhances our development process.
                        </p>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Rapid Development</h5>
                            <p className="mt-2 text-gray-600">
                                Our Ruby on Rails developers utilize the framework’s conventions to achieve rapid application development, saving you time and resources.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Scalability</h5>
                            <p className="mt-2 text-gray-600">
                                We build scalable applications that can grow with your business, ensuring long-term success.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Maintenance and Support</h5>
                            <p className="mt-2 text-gray-600">
                                Our team provides ongoing maintenance and support to keep your application running smoothly and up to date.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Cost-Effective Solutions</h5>
                            <p className="mt-2 text-gray-600">
                                We offer competitive pricing and high-quality solutions, providing exceptional value for your investment.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <ExpertDetail title={'Ruby on Rails'} />
        </div>
    );
}

export default RubyOnRailsDevelopment;
