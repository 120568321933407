import React from 'react';
import { Icons } from '../assets/imageIndex';
import ProcessSection from './ProcessSection';

const CyberSecurityServices = () => {
    return (
        <div>
            <section
                className="relative px-4 md:px-0 team bg-cover bg-center section-padding bg-opacity-100 py-16"
                style={{ backgroundImage: `url(${Icons.stepImg})` }}
            >
                <div className="full-width mx-auto">
                    <div className="mb-8 text-center">
                        <h1 className="text-4xl font-bold">Cybersecurity Services</h1>
                        <div className="border-t-2 border-gray-300 w-16 mx-auto mt-4"></div>
                    </div>

                    <p className="text-gray-600 text-left">
                        At Covantis Sarvagis Technologies, we offer advanced cybersecurity services to protect your business against ever-evolving cyber threats. Our comprehensive solutions ensure your digital assets are secure, your data is protected, and your business stays compliant with industry regulations.
                    </p>
                </div>
            </section>

            <section className="py-16 px-4 md:px-0 bg-gray-50">
                <div className="container mx-auto">
                    <div className="text-center mb-10" data-aos="fade-up" data-aos-duration="1500">
                        <h2 className="text-3xl font-semibold">Comprehensive Cybersecurity Solutions</h2>
                        <p className="mt-4 text-gray-700">
                            Our cybersecurity services cover everything from proactive threat detection to incident response, safeguarding your business from digital risks and ensuring continuous security for your operations.
                        </p>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Threat Detection & Monitoring</h5>
                            <p className="mt-2 text-gray-600">
                                We provide 24/7 monitoring and threat detection services to identify and neutralize cyber threats before they can cause harm to your business.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Vulnerability Assessment & Penetration Testing</h5>
                            <p className="mt-2 text-gray-600">
                                Our experts conduct thorough vulnerability assessments and penetration testing to expose weaknesses in your security infrastructure and recommend actionable fixes.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Data Encryption & Protection</h5>
                            <p className="mt-2 text-gray-600">
                                We implement advanced encryption techniques to protect your sensitive data from unauthorized access, ensuring data integrity and compliance with regulations.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Network Security</h5>
                            <p className="mt-2 text-gray-600">
                                Our network security services protect your infrastructure from intrusions, ensuring secure communication and preventing unauthorized access to your systems.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Incident Response & Recovery</h5>
                            <p className="mt-2 text-gray-600">
                                In the event of a breach, our incident response team acts quickly to contain the threat, mitigate damage, and restore your systems to full functionality.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Security Compliance & Audits</h5>
                            <p className="mt-2 text-gray-600">
                                We ensure your business complies with industry-specific regulations such as GDPR, HIPAA, and PCI-DSS, providing comprehensive audits and guidance on staying compliant.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <ProcessSection />
        </div>
    );
}

export default CyberSecurityServices;
