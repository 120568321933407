import React from 'react';
import { Icons } from '../assets/imageIndex';
import ExpertDetail from './ExpertDetail';

const AndroidDevelopment = () => {
    return (
        <div>
            <section
                className="relative px-4 md:px-0 team bg-cover bg-center section-padding bg-opacity-100 py-16"
                style={{ backgroundImage: `url(${Icons.stepImg})` }}
            >
                <div className="full-width mx-auto">
                    <div className="mb-8 text-center">
                        <h1 className="text-4xl font-bold">Android Development Services</h1>
                        <div className="border-t-2 border-gray-300 w-16 mx-auto mt-4"></div>
                    </div>

                    <p className="text-gray-600 text-left">
                        Hire knowledgeable Android app developers from Covantis Sarvagis to leverage the latest Android technologies, including Android SDK, Java, Kotlin, and various development tools. We utilize advanced frameworks and libraries to deliver high-quality, feature-rich applications tailored to your business needs.

                        Our customer-centric approach ensures that we align your project with your unique requirements. When you hire top Android app developers from Covantis Sarvagis, you benefit from a flexible engagement model. Our commitment to thorough quality checks at every stage of development is why we are recognized as a leading Android app development company.
                    </p>
                </div>
            </section>

            <section className="py-16 px-4 md:px-0 bg-gray-50">
                <div className="full-width mx-auto">
                    <div className="text-center mb-10" data-aos="fade-up" data-aos-duration="1500">
                        <h2 className="text-3xl font-semibold">Why Hire Android Developers From Covantis Sarvagis</h2>
                        <p className="mt-4 text-gray-700">
                            Employ dedicated Android developers from Covantis Sarvagis, who are well-versed in their methodologies and know how to implement them effectively in alignment with your business goals. Our foundation in agile methodologies enhances our development process.
                        </p>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Custom Solutions</h5>
                            <p className="mt-2 text-gray-600">
                                Our Android developers provide custom solutions that cater to your unique business requirements and user expectations.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Enhanced User Experience</h5>
                            <p className="mt-2 text-gray-600">
                                We prioritize user experience by creating intuitive and engaging interfaces, ensuring high user satisfaction and retention.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Regular Updates</h5>
                            <p className="mt-2 text-gray-600">
                                Our team ensures your Android app stays updated with the latest trends and technologies, providing long-term support and maintenance.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Cost-Effective Development</h5>
                            <p className="mt-2 text-gray-600">
                                We offer competitive pricing without compromising on quality, delivering value for your investment.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <ExpertDetail title={'Android'} />
        </div>
    );
}

export default AndroidDevelopment;
