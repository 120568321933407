import React from 'react';
import { Icons } from '../assets/imageIndex';
import ProcessSection from './ProcessSection';

const CloudServices = () => {
    return (
        <div>
            <section
                className="relative px-4 md:px-0 team bg-cover bg-center section-padding bg-opacity-100 py-16"
                style={{ backgroundImage: `url(${Icons.stepImg})` }}
            >
                <div className="full-width mx-auto">
                    <div className="mb-8 text-center">
                        <h1 className="text-4xl font-bold">Cloud Services Services</h1>
                        <div className="border-t-2 border-gray-300 w-16 mx-auto mt-4"></div>
                    </div>

                    <p className="text-gray-600 text-left">
                        At Covantis Sarvagis Technologies, we provide comprehensive cloud services that
                         empower businesses to enhance flexibility, scalability, and security. Our expert team specializes in cloud migration, deployment, and management, ensuring that your organization can leverage the full potential of cloud computing to drive innovation and efficiency.
                    </p>
                </div>
            </section>

            <section className="py-16 px-4 md:px-0 bg-gray-50">
                <div className="container mx-auto">
                    <div className="text-center mb-10" data-aos="fade-up" data-aos-duration="1500">
                        <h2 className="text-3xl font-semibold">Unlock the Power of the Cloud</h2>
                        <p className="mt-4 text-gray-700">
                            We offer a range of cloud services tailored to meet your specific business needs, ensuring a seamless transition to the cloud.
                        </p>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Cloud Migration</h5>
                            <p className="mt-2 text-gray-600">
                                Our team facilitates a smooth migration process, moving your applications and data to the cloud without disruption, ensuring minimal downtime.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Cloud Infrastructure Management</h5>
                            <p className="mt-2 text-gray-600">
                                We provide ongoing management of your cloud infrastructure, optimizing performance, security, and costs to ensure efficient operations.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Cloud Security Solutions</h5>
                            <p className="mt-2 text-gray-600">
                                Our cloud security services safeguard your data and applications against threats, ensuring compliance with industry regulations.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Disaster Recovery</h5>
                            <p className="mt-2 text-gray-600">
                                We implement robust disaster recovery solutions that protect your critical data and applications, ensuring business continuity in case of disruptions.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Cloud Consulting</h5>
                            <p className="mt-2 text-gray-600">
                                Our consulting services help you design a cloud strategy that aligns with your business goals, maximizing your investment in cloud technology.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Multi-Cloud Solutions</h5>
                            <p className="mt-2 text-gray-600">
                                We offer multi-cloud strategies to help you leverage the strengths of different cloud providers, enhancing flexibility and reducing vendor lock-in.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <ProcessSection />
        </div>
    );
}

export default CloudServices;
