import React from 'react'
import { Icons } from '../assets/imageIndex'
import ProcessSection from './ProcessSection'

const WebDevelopment = () => {
    return (
        <div>
            <section
                className="relative team  px-4 md:px-0 bg-cover bg-center section-padding bg-opacity-100 py-16"
                style={{ backgroundImage: `url(${Icons.stepImg})` }}
            >
                <div className="full-width mx-auto">
                    <div className="mb-8 text-center">
                        <h1 className="text-4xl font-bold" data-splitting="">High-Performing Web Application Development</h1>
                        <div className="border-t-2 border-gray-300 w-16 mx-auto mt-4"></div>
                    </div>

                    <p className="text-gray-600 text-left">we create web
                        solutions that are ready to RACE (Reach, Attract,
                        Communicate, and Engage) your audience. We use the right tech stacks and focus on building fully functional web applications that help you achieve maximum growth. Our end goal is to create quality web apps at a low cost that aligns with your business objectives.</p>
                </div>
            </section>
            <section className="py-16 px-4 md:px-0 bg-gray-50">
                <div className="full-width mx-auto">
                    <div className="text-center mb-10" data-aos="fade-up" data-aos-duration="1500">
                        <h2 className="text-3xl font-semibold">UI/UX Design Services from Our Industry Experts</h2>
                    </div>
                    <div className="max-w-7xl mx-auto p-4">
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">

                            <div className="flex flex-col bg-white" data-aos="fade-up" data-aos-duration="1500">
                                <div className="p-4 rounded shadow-md md:h-96 ">
                                    <h5 className="text-lg font-semibold">Frontend Development Service</h5>
                                    <p className="text-gray-600 text-left">
                                        We are a leading <b>website development company,</b> creating a seamless user experience. We specialize in building responsive designs that adapt seamlessly to various screen sizes and resolutions. We use cutting-edge tools to create high-performing websites with cross-browser compatibility. Our frontend development team employs optimization techniques to minimize load times and enhance overall website performance. We maintain a transparent and collaborative approach to elevate your digital presence.
                                    </p>
                                </div>
                            </div>

                            <div className="flex flex-col bg-white" data-aos="fade-up" data-aos-duration="1500">
                               <div className="p-4 rounded shadow-md md:h-96 ">
                                    <h5 className="text-lg font-semibold">Backend Development Service</h5>
                                    <p className="text-gray-600 text-left">
                                        Our backend development team can design and implement backend architectures that grow with your business. We empower your business with powerful APIs to enable seamless communication between different components of your application. We leverage server-side scripting languages like Node.js, Python, and Ruby on Rails to process data and ensure the efficient functioning of your web application. Regardless of your requirements, we seamlessly integrate third-party apps for a feature-rich user experience.
                                    </p>
                                </div>
                            </div>

                            <div className="flex flex-col bg-white" data-aos="fade-up" data-aos-duration="1500">
                               <div className="p-4 rounded shadow-md md:h-96 ">
                                    <h5 className="text-lg font-semibold">Progressive Web Apps Development</h5>
                                    <p className="text-gray-600 text-left">
                                        At our <b>top website development company,</b> we bring the best of both worlds by developing robust PWAs that offer a native-like experience. Our developers can craft high-performing PWAs to bridge the gap between web and mobile applications. We adhere to well-planned development to ensure compatibility with different browsers and operating systems for a seamless user experience. We strive to develop web apps with optimized performance and reduced load times that can improve your search engine ranking.
                                    </p>
                                </div>
                            </div>

                            <div className="flex flex-col bg-white" data-aos="fade-up" data-aos-duration="1500">
                               <div className="p-4 rounded shadow-md md:h-96 ">
                                    <h5 className="text-lg font-semibold">E-commerce Web Apps Development</h5>
                                    <p className="text-gray-600 text-left">
                                        As a full-service <b>web development company,</b> we create custom eCommerce solutions that align with your business goals, brand identity, and user expectations. We implement web optimization for responsive design so your customers can browse and make purchases effortlessly across different devices. We integrate eCommerce web apps with secure payment gateways for safe and smooth processing of transactions. Our designed web apps are search engine friendly, which enhances your online visibility and maximizes your digital reach.
                                    </p>
                                </div>
                            </div>

                            <div className="flex flex-col bg-white" data-aos="fade-up" data-aos-duration="1500">
                               <div className="p-4 rounded shadow-md md:h-96 ">
                                    <h5 className="text-lg font-semibold">Custom Web Apps Development</h5>
                                    <p className="text-gray-600 text-left">
                                        Our custom web app development service is best suited for unique organizations with different needs. We create custom web applications that are designed to address your business challenges, streamline operations, and provide a personalized digital experience for your users. We follow a strategic collaboration approach to define the scope, features, and goals of your custom web application. Our developers integrate custom solutions with your software stack to ensure a smooth transition and minimal disruption.
                                    </p>
                                </div>
                            </div>

                            <div className="flex flex-col bg-white" data-aos="fade-up" data-aos-duration="1500">
                               <div className="p-4 rounded shadow-md md:h-96 ">
                                    <h5 className="text-lg font-semibold">Web App Support and Maintenance</h5>
                                    <p className="text-gray-600 text-left">
                                        At Covantis Sarvagis Technologies, we offer round-the-clock web app support and maintenance for the longevity and optimal performance of your website. We proactively monitor your web application and employ advanced monitoring tools to track performance and identify potential issues. We stay up-to-date with the latest security trends and technologies to protect your application against emerging threats.
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <ProcessSection />
        </div>
    )
}

export default WebDevelopment