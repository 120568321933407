import React from 'react'
import LetsTalk from '../component/LetsTalk'
import TitleHeader from '../component/TitleHeader'
import ServicesSection from '../component/ServicesSection'
import ClientsSection from '../component/ClientsSection'
import { Icons } from '../assets/imageIndex'
import FaqSection from '../component/FaqSection'

const ServicesScreen = () => {
  return (
    <div>
      <TitleHeader title={'Services'} />
      <br />
      <br />
      <ServicesSection title={'service'} />
      <section
        className="services px-4 md:px-0 section-padding my-7 pt-16 bg-cover bg-center"
        style={{ backgroundImage: `url(${Icons.stepImg})` }}
      >
        <div className="full-width mx-auto">
          <div className="mb-8 text-center">
            <h6 className="wow" data-splitting="">Our Work Process</h6>
            <h1 className="wow" data-splitting="">How We Work</h1>
            <div className="border-t-2 border-gray-300 w-16 mx-auto mt-4"></div>
          </div>

          {/* Process Cards */}
          <div className="grid grid-cols-1 sm:grid-cols-4 gap-6">

            {/* Step 1: Understanding Client Needs */}
            <div data-aos="fade-up" data-aos-duration="1500">
              <div className="bg-white shadow-lg p-6 rounded-lg text-center">
                <div className=" text-orange-500 text-5xl mb-4">
                  <img src={Icons.workflow} alt='Understanding Client Needs' className='w-14' />
                </div>
                <h3 className="text-lg font-semibold mb-2">Understanding Client Needs</h3>
                <p className="text-gray-600">We start by gathering detailed requirements to understand the project scope, goals, and client vision.</p>
              </div>
            </div>

            {/* Step 2: Strategic Project Analysis */}
            <div data-aos="fade-up" data-aos-duration="1500">
              <div className="bg-white shadow-lg p-6 rounded-lg text-center">
                <div className=" text-orange-500 text-5xl mb-4">
                  <img src={Icons.digitalServices} alt='Strategic Project Analysis' className='w-14' />
                </div>
                <h3 className="text-lg font-semibold mb-2">Strategic Project Analysis</h3>
                <p className="text-gray-600">Our team evaluates the project needs, suggesting the best technical solutions to achieve your goals efficiently.</p>
              </div>
            </div>

            {/* Step 3: Development & Execution */}
            <div data-aos="fade-up" data-aos-duration="1500">
              <div className="bg-white shadow-lg p-6 rounded-lg text-center">
                <div className=" text-orange-500 text-5xl mb-4">
                  <img src={Icons.experience} alt='Development & Execution' className='w-14' />
                </div>
                <h3 className="text-lg font-semibold mb-2">Development & Execution</h3>
                <p className="text-gray-600">Our skilled developers and designers turn your ideas into reality, delivering functional and aesthetic solutions.</p>
              </div>
            </div>

            {/* Step 4: Quality Assurance & Delivery */}
            <div data-aos="fade-up" data-aos-duration="1500">
              <div className="bg-white shadow-lg p-6 rounded-lg text-center">
                <div className=" text-orange-500 text-5xl mb-4">
                  <img src={Icons.qualityAssurance} alt='Quality Assurance & Delivery' className='w-14' />
                </div>
                <h3 className="text-lg font-semibold mb-2">Quality Assurance & Delivery</h3>
                <p className="text-gray-600">We ensure everything is perfect before launch, delivering high-quality solutions on time and on budget.</p>
              </div>
            </div>

          </div>
        </div>
      </section>
      <ClientsSection />
      <FaqSection />
      <LetsTalk />
    </div>
  )
}

export default ServicesScreen