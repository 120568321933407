import React from 'react';
import { Icons } from '../assets/imageIndex';

const ProcessSection = () => {
  return (
    <section className="team bg-center mt-7 section-padding py-16">
      <div className="full-width mx-auto">
        <div className="mb-12 text-center">
          <h6 className="text-gray-500">Work Process</h6>
          <h1 className="text-4xl font-bold">Follow Our 4-Step Work Process</h1>
          <div className="w-16 border-t-2 border-gray-300 mx-auto mt-4"></div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-4 gap-6">

          {/* Step 1: Initial Consultation */}
          <div className='mx-4 md:mx-1' data-aos="fade-up" data-aos-duration="1500">
            <div className="text-center">
              <div className="process-item fade-top flex flex-col items-center text-center opacity-100">
                <div className="process-icon icon-2 ">
                  <div className="icon-border"></div>
                  <img src={Icons.workflow} className='w-14' alt="Initial Consultation"  />
                </div>
                <div className="process-content">
                  <h5 className="title text-black mt-2">Initial Consultation</h5>
                  <p>We begin by discussing your project requirements in detail to ensure we understand your vision and goals.</p>
                </div>
              </div>
            </div>
          </div>

          {/* Step 2: Project Planning */}
          <div className='mx-4 md:mx-1' data-aos="fade-up" data-aos-duration="1500">
            <div className="text-center">
              <div className="process-item fade-top flex flex-col items-center text-center opacity-100">
                <div className="process-icon icon-2">
                  <div className="icon-border"></div>
                  <img src={Icons.qualityAssurance} alt='Project Planning' className='w-14' />
                </div>
                <div className="process-content">
                  <h5 className="title text-black mt-2">Project Planning</h5>
                  <p>Our team conducts in-depth analysis and creates a strategic plan to align the project with your goals.</p>
                </div>
              </div>
            </div>
          </div>

          {/* Step 3: Design & Development */}
          <div className='mx-4 md:mx-1' data-aos="fade-up" data-aos-duration="1500">
            <div className="text-center">
              <div className="process-item fade-top flex flex-col items-center text-center opacity-100">
                <div className="process-icon icon-2">
                  <div className="icon-border"></div>
                  <img src={Icons.premiumQuality} className='w-14' alt='Design & Development' />
                </div>
                <div className="process-content">
                  <h5 className="title text-black mt-2">Design & Development</h5>
                  <p>Our designers and developers collaborate to bring your project to life, ensuring a balance of aesthetics and functionality.</p>
                </div>
              </div>
            </div>
          </div>

          {/* Step 4: Final Delivery */}
          <div className='mx-4 md:mx-1' data-aos="fade-up" data-aos-duration="1500">
            <div className="text-center">
              <div className="process-item fade-top flex flex-col items-center text-center opacity-100">
                <div className="process-icon icon-2">
                  <div className="icon-border"></div>
                  <img src={Icons.guaranteed} className='w-14' alt='Final Delivery' />
                </div>
                <div className="process-content">
                  <h5 className="title text-black mt-2">Final Delivery</h5>
                  <p>We ensure that the final product is thoroughly tested, delivering a polished solution that meets your expectations.</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
};

export default ProcessSection;
