import React from 'react';
import { Icons } from '../assets/imageIndex';
import ExpertDetail from './ExpertDetail';

const NodeJsDevelopment = () => {
    return (
        <div>
            <section
                className="relative px-4 md:px-0 team bg-cover bg-center section-padding bg-opacity-100 py-16"
                style={{ backgroundImage: `url(${Icons.stepImg})` }}
            >
                <div className="full-width mx-auto">
                    <div className="mb-8 text-center">
                        <h1 className="text-4xl font-bold">Node.js Development Services</h1>
                        <div className="border-t-2 border-gray-300 w-16 mx-auto mt-4"></div>
                    </div>

                    <p className="text-gray-600 text-left">
                        Hire experienced Node.js developers from Covantis Sarvagis to build scalable and efficient server-side applications. Node.js is a powerful JavaScript runtime that allows for the development of fast and high-performance applications, enabling seamless data handling and real-time capabilities.

                        Our customer-focused approach ensures that we align your project with your unique business needs, while our rigorous quality assurance processes maintain high standards throughout development.
                    </p>
                </div>
            </section>

            <section className="py-16 px-4 md:px-0 bg-gray-50">
                <div className="full-width mx-auto">
                    <div className="text-center mb-10" data-aos="fade-up" data-aos-duration="1500">
                        <h2 className="text-3xl font-semibold">Why Hire Node.js Developers From Covantis Sarvagis</h2>
                        <p className="mt-4 text-gray-700">
                            Our dedicated Node.js developers leverage the latest technologies and frameworks to deliver high-quality applications tailored to your requirements. We adopt agile methodologies to enhance collaboration and ensure responsiveness throughout the development process.
                        </p>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Asynchronous and Event-Driven</h5>
                            <p className="mt-2 text-gray-600">
                                Node.js operates on a non-blocking I/O model, making it efficient and suitable for handling multiple requests simultaneously.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Microservices Architecture</h5>
                            <p className="mt-2 text-gray-600">
                                We utilize microservices architecture for enhanced scalability and maintainability of applications.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Real-Time Applications</h5>
                            <p className="mt-2 text-gray-600">
                                Node.js is ideal for building real-time applications such as chat applications and live tracking systems.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Strong Community Support</h5>
                            <p className="mt-2 text-gray-600">
                                Node.js has a large and active community, providing a wealth of libraries and resources to enhance development.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <ExpertDetail title={'Node js'} />
        </div>
    );
}

export default NodeJsDevelopment;
