import React from 'react';
import TitleHeader from '../component/TitleHeader';

const PrivacyPolicy = () => {
    return (
        <div className="bg-gray-100">
            <div className=" bg-white  shadow-md rounded-lg">
                <TitleHeader title="Privacy Policy" />
                <div className='text-left mx-7'>
                <p className="text-gray-600 mt-4">
                    This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.
                </p>
                <p className="text-gray-600 mt-2">
                    We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.
                </p>

                <h2 className="text-2xl font-semibold text-gray-800 mt-8">Interpretation and Definitions</h2>
                <h3 className="text-xl font-semibold text-gray-700 mt-6">Interpretation</h3>
                <p className="text-gray-600 mt-2">
                    The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
                </p>

                <h3 className="text-xl font-semibold text-gray-700 mt-6">Definitions</h3>
                <p className="text-gray-600 mt-2">For the purposes of this Privacy Policy:</p>
                <ul className="list-disc list-inside text-gray-600 mt-4 space-y-2">
                    <li><strong>Account</strong> means a unique account created for You to access our Service or parts of our Service.</li>
                    <li><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control with a party.</li>
                    <li><strong>Company</strong> refers to Covantis Sarvagis Technologies Private Limited, Delhi, India.</li>
                    <li><strong>Cookies</strong> are small files that are placed on Your computer, mobile device, or any other device by a website, containing details of Your browsing history.</li>
                    <li><strong>Country</strong> refers to Delhi, India.</li>
                    <li><strong>Device</strong> means any device that can access the Service, such as a computer, cellphone, or tablet.</li>
                    <li><strong>Personal Data</strong> is any information that relates to an identified or identifiable individual.</li>
                    <li><strong>Service</strong> refers to the Website.</li>
                    <li><strong>Website</strong> refers to Covantis Sarvagis
                     Technologies, accessible from 
                     <a href="https://covantis.in/" className="text-blue-600 hover:underline"> www.covantis.in</a></li>
                    <li><strong>You</strong> means the individual accessing or using the Service.</li>
                </ul>

                <h2 className="text-2xl font-semibold text-gray-800 mt-8">Collecting and Using Your Personal Data</h2>
                <h3 className="text-xl font-semibold text-gray-700 mt-6">Types of Data Collected</h3>
                <h4 className="text-lg font-semibold text-gray-700 mt-4">Personal Data</h4>
                <p className="text-gray-600 mt-2">While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You.</p>
                <ul className="list-disc list-inside text-gray-600 mt-4 space-y-2">
                    <li>Email address</li>
                    <li>First name and last name</li>
                    <li>Phone number</li>
                    <li>Usage Data</li>
                </ul>

                <h4 className="text-lg font-semibold text-gray-700 mt-6">Usage Data</h4>
                <p className="text-gray-600 mt-2">Usage Data is collected automatically when using the Service, including but not limited to IP address, browser type, and pages visited.</p>

                <h4 className="text-lg font-semibold text-gray-700 mt-6">Information from Third-Party Social Media Services</h4>
                <p className="text-gray-600 mt-2">We may allow You to create an account through services like Google, Facebook, etc. We may collect data like Your name, email, and activities from these services.</p>

                <h4 className="text-lg font-semibold text-gray-700 mt-6">Tracking Technologies and Cookies</h4>
                <p className="text-gray-600 mt-2">We use Cookies and similar technologies to track activity on Our Service and store certain information. You can control cookie usage through your browser settings.</p>

                <h3 className="text-xl font-semibold text-gray-700 mt-8">Use of Your Personal Data</h3>
                <p className="text-gray-600 mt-2">The Company may use Personal Data for the following purposes:</p>
                <ul className="list-disc list-inside text-gray-600 mt-4 space-y-2">
                    <li><strong>To provide and maintain our Service</strong></li>
                    <li><strong>To manage Your Account</strong></li>
                    <li><strong>For business transfers:</strong> We may share your information during business negotiations like mergers.</li>
                    <li><strong>To contact You:</strong> By email, phone, SMS, or other communication forms.</li>
                </ul>

                <h3 className="text-xl font-semibold text-gray-700 mt-8">Security of Your Personal Data</h3>
                <p className="text-gray-600 mt-2">We strive to protect Your data, but no method of transmission or electronic storage is 100% secure.</p>

                <h3 className="text-xl font-semibold text-gray-700 mt-8">Children's Privacy</h3>
                <p className="text-gray-600 mt-2">Our Service does not knowingly collect data from anyone under the age of 13.</p>

                <h2 className="text-2xl font-semibold text-gray-800 mt-8">Links to Other Websites</h2>
                <p className="text-gray-600 mt-2">Our Service may contain links to external websites that are not operated by Us.</p>

                <p className="text-gray-600 mt-6 m-0 pb-10">If you have any questions, feel free to contact us at <a href="mailto:info@covantis.in" className="text-blue-600 hover:underline">info@covantis.in</a>.</p>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
