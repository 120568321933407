import React from 'react';
import { Icons } from '../assets/imageIndex';
import ExpertDetail from './ExpertDetail';

const AngularDevelopment = () => {
    return (
        <div>
            <section
                className="relative px-4 md:px-0 team bg-cover bg-center section-padding bg-opacity-100 py-16"
                style={{ backgroundImage: `url(${Icons.stepImg})` }}
            >
                <div className="full-width mx-auto">
                    <div className="mb-8 text-center">
                        <h1 className="text-4xl font-bold">Angular Development Services</h1>
                        <div className="border-t-2 border-gray-300 w-16 mx-auto mt-4"></div>
                    </div>

                    <p className="text-gray-600 text-left">
                        Elevate your web applications with our expert Angular development services at Covantis Sarvagis. Our skilled Angular developers leverage the power of this robust framework to create dynamic and responsive applications that provide a seamless user experience.

                        From single-page applications to complex enterprise solutions, we deliver scalable and maintainable code that meets your business needs while ensuring optimal performance.
                    </p>
                </div>
            </section>

            <section className="py-16 px-4 md:px-0 bg-gray-50">
                <div className="full-width mx-auto">
                    <div className="text-center mb-10" data-aos="fade-up" data-aos-duration="1500">
                        <h2 className="text-3xl font-semibold">Why Hire Angular Developers From Covantis Sarvagis</h2>
                        <p className="mt-4 text-gray-700">
                            Our dedicated Angular developers possess extensive knowledge of the framework, ensuring that your project benefits from the latest features and best practices. We follow agile methodologies to foster collaboration and adaptability throughout the development process.
                        </p>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Component-Based Architecture</h5>
                            <p className="mt-2 text-gray-600">
                                Build reusable components that enhance maintainability and scalability of your applications.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">High Performance</h5>
                            <p className="mt-2 text-gray-600">
                                Leverage Angular’s optimization techniques for fast and efficient applications.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Strong Community Support</h5>
                            <p className="mt-2 text-gray-600">
                                Benefit from a vast ecosystem and community support that continuously enhances the framework.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Seamless Integration</h5>
                            <p className="mt-2 text-gray-600">
                                Easily integrate with other libraries and technologies to extend your application's functionality.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <ExpertDetail title={'Angular'} />
        </div>
    );
}

export default AngularDevelopment;
