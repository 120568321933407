import React from 'react';
import { Icons } from '../assets/imageIndex';
import ExpertDetail from './ExpertDetail';

const JavaDevelopment = () => {
    return (
        <div>
            <section
                className="relative px-4 md:px-0 team bg-cover bg-center section-padding bg-opacity-100 py-16"
                style={{ backgroundImage: `url(${Icons.stepImg})` }}
            >
                <div className="full-width mx-auto">
                    <div className="mb-8 text-center">
                        <h1 className="text-4xl font-bold">Java Development Services</h1>
                        <div className="border-t-2 border-gray-300 w-16 mx-auto mt-4"></div>
                    </div>

                    <p className="text-gray-600 text-left">
                        Hire experienced Java developers from Covantis Sarvagis to build robust, scalable, and high-performance applications. With a rich ecosystem of frameworks and libraries, our team can create solutions that fit your business needs, whether it's enterprise-level applications, web services, or mobile apps.

                        We follow a customer-centric approach to align your project requirements with our technical expertise. By choosing top Java developers from Covantis Sarvagis, you gain access to flexible engagement models and a commitment to quality throughout the development lifecycle.
                    </p>
                </div>
            </section>

            <section className="py-16 px-4 md:px-0 bg-gray-50">
                <div className="full-width mx-auto">
                    <div className="text-center mb-10" data-aos="fade-up" data-aos-duration="1500">
                        <h2 className="text-3xl font-semibold">Why Hire Java Developers From Covantis Sarvagis</h2>
                        <p className="mt-4 text-gray-700">
                            Employ dedicated Java developers from Covantis Sarvagis, who are proficient in Java’s best practices and frameworks, ensuring effective implementation aligned with your business strategy. Our agile methodology enhances collaboration and responsiveness throughout the project.
                        </p>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Robust Frameworks</h5>
                            <p className="mt-2 text-gray-600">
                                Our developers utilize powerful frameworks like Spring and Hibernate to build secure and scalable applications.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Cross-Platform Development</h5>
                            <p className="mt-2 text-gray-600">
                                With Java, we can create applications that run seamlessly across different platforms, ensuring broad accessibility.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">High Performance</h5>
                            <p className="mt-2 text-gray-600">
                                Java's performance optimization techniques enable us to deliver applications that are both fast and efficient.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Long-Term Support</h5>
                            <p className="mt-2 text-gray-600">
                                We offer ongoing support and maintenance to ensure your Java applications remain up to date and perform optimally.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <ExpertDetail title={'Java'} />
        </div>
    );
}

export default JavaDevelopment;
