import React from 'react'
import { Icons } from '../assets/imageIndex'
import ProcessSection from './ProcessSection'

const SocialNetworkDevelopment = () => {
    return (
        <div>
            <section
                className="relative px-4 md:px-0 team bg-cover bg-center section-padding bg-opacity-100 py-16"
                style={{ backgroundImage: `url(${Icons.stepImg})` }}
            >
                <div className="full-width mx-auto">
                    <div className="mb-8 text-center">
                        <h1 className="text-4xl font-bold">Social Network Development Services</h1>
                        <div className="border-t-2 border-gray-300 w-16 mx-auto mt-4"></div>
                    </div>

                    <p className="text-gray-600 text-left">
                        At Covantis Sarvagis Technologies, we specialize in creating dynamic and engaging social networking platforms 
                        tailored to your specific needs. Our social network development services are designed to connect users, foster engagement, and build communities. We utilize cutting-edge technology and user-centric design principles to deliver platforms that facilitate seamless interactions and collaboration among users.
                    </p>
                </div>
            </section>

            <section className="py-16 px-4 md:px-0 bg-gray-50">
                <div className="container mx-auto">
                    <div className="text-center mb-10" data-aos="fade-up" data-aos-duration="1500">
                        <h2 className="text-3xl font-semibold">Social Network Development Services from Our Experts</h2>
                        <p className="mt-4 text-gray-600">
                            We are a leading social network development company focused on creating innovative solutions that enhance user connectivity and interaction.
                        </p>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                         <div className="bg-white p-6 shadow-md rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Custom Social Platforms</h5>
                            <p className="mt-2 text-gray-600">
                                We develop custom social networking platforms that cater to your unique requirements, ensuring a personalized user experience.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-md rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Community Building Tools</h5>
                            <p className="mt-2 text-gray-600">
                                Our solutions include robust tools for community building, allowing users to create groups, share content, and engage with one another effectively.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-md rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Real-time Messaging</h5>
                            <p className="mt-2 text-gray-600">
                                We integrate real-time messaging features that facilitate seamless communication among users, enhancing the overall social experience.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-md rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Content Sharing Features</h5>
                            <p className="mt-2 text-gray-600">
                                Our platforms support various content sharing options, allowing users to post updates, share photos, and engage with multimedia content.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-md rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">User Engagement Analytics</h5>
                            <p className="mt-2 text-gray-600">
                                We provide analytics tools to help you understand user behavior and engagement patterns, enabling you to optimize your platform continually.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-md rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Mobile-Friendly Solutions</h5>
                            <p className="mt-2 text-gray-600">
                                Our social networking solutions are designed to be mobile-friendly, ensuring users can connect and interact on any device, anywhere.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <ProcessSection />
        </div>
    )
}

export default SocialNetworkDevelopment