import React from 'react';
import { Icons } from '../assets/imageIndex';
import ExpertDetail from './ExpertDetail';

const FlutterDevelopment = () => {
    return (
        <div>
            <section
                className="relative px-4 md:px-0 team bg-cover bg-center section-padding bg-opacity-100 py-16"
                style={{ backgroundImage: `url(${Icons.stepImg})` }}
            >
                <div className="full-width mx-auto">
                    <div className="mb-8 text-center">
                        <h1 className="text-4xl font-bold">Flutter Development Services</h1>
                        <div className="border-t-2 border-gray-300 w-16 mx-auto mt-4"></div>
                    </div>

                    <p className="text-gray-600 text-left">
                        Hire skilled Flutter developers from Covantis Sarvagis to create beautiful, natively compiled applications for mobile, web, and desktop from a single codebase. Our team harnesses the power of Flutter and its rich set of widgets to deliver high-performance applications tailored to your business needs.

                        We adopt a customer-centric approach to align your project with specific requirements. When you hire top Flutter developers from Covantis Sarvagis, you benefit from flexible engagement models and a commitment to rigorous quality checks at every stage of the development process.
                    </p>
                </div>
            </section>

            <section className="py-16 px-4 md:px-0 bg-gray-50">
                <div className="full-width mx-auto">
                    <div className="text-center mb-10" data-aos="fade-up" data-aos-duration="1500">
                        <h2 className="text-3xl font-semibold">Why Hire Flutter Developers From Covantis Sarvagis</h2>
                        <p className="mt-4 text-gray-700">
                            Employ dedicated Flutter developers from Covantis Sarvagis, who are well-versed in Flutter's framework and know how to implement best practices effectively to align with your business goals. Our expertise in agile methodologies enhances our development process.
                        </p>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Cross-Platform Development</h5>
                            <p className="mt-2 text-gray-600">
                                Our developers leverage Flutter to create applications that work seamlessly across iOS, Android, and web platforms from a single codebase.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Rich User Interface</h5>
                            <p className="mt-2 text-gray-600">
                                With Flutter, we create visually appealing applications that provide an engaging user experience, thanks to its extensive widget library.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Faster Time to Market</h5>
                            <p className="mt-2 text-gray-600">
                                Our agile development process enables quick iterations, helping you launch your application faster without compromising quality.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Ongoing Support</h5>
                            <p className="mt-2 text-gray-600">
                                We offer continuous maintenance and support services to ensure your Flutter application stays up to date and performs optimally.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <ExpertDetail title={'Flutter'} />
        </div>
    );
}

export default FlutterDevelopment;
