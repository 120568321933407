import React from 'react'
import { Icons } from '../assets/imageIndex'
import ProcessSection from './ProcessSection'

const MobileDevelopment = () => {
    return (
        <div>
            <section
                className="relative px-4 md:px-0 team  bg-cover bg-center section-padding bg-opacity-100 py-16"
                style={{ backgroundImage: `url(${Icons.stepImg})` }}
            >
                <div className="full-width mx-auto">
                    <div className="mb-8 text-center">
                        <h1 className="text-4xl font-bold" data-splitting="">Enhance Your Business Performance With  Mobile Application</h1>
                        <div className="border-t-2 border-gray-300 w-16 mx-auto mt-4"></div>
                    </div>

                    <p className="text-gray-600 text-left">At Covantis Sarvagis  Technologies, we aim to bring your unique app idea to life through our end-to-end mobile app development services. Our mobile app development professionals have years of expertise in creating scalable and flexible mobile applications for a variety of industries. They will assist you throughout the development stages and provide full-cycle mobile app solutions to accelerate your business growth. With our app developers on your side, you can reach new success standards and create profitable business revenue streams.</p>

                </div>
            </section>
            <section className="py-16 px-4 md:px-0 bg-gray-50">
                <div className="full-width mx-auto">
                    <div className="text-center mb-10" data-aos="fade-up" data-aos-duration="1500">
                        <h2 className="text-3xl font-semibold">Our development cycle for app development</h2>
                    </div>
                    <div className="max-w-7xl mx-auto px-4">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-14">
                            <div className="flex justify-start" data-aos="fade-up" data-aos-duration="1500">
                                <img src={Icons.mobiImg1}
                                    className="w-full object-cover"
                                    alt=' Mobile App Consulting'
                                />
                            </div>

                            <div className="flex flex-col" data-aos="fade-up" data-aos-duration="1500">
                                <div className="mb-6">
                                    <h3 className="title text-lg font-semibold text-left">

                                        Mobile App Consulting
                                    </h3>
                                    <p className="text-gray-600 text-left">
                                        Unlike other app development companies, our priorities and commitment lie in understanding your vision and requirements. We closely work with you to discuss your project ideas, goals, and requirements. Depending on your input, we conduct thorough market research and feasibility analysis to define the project requirements. We always follow a well-defined strategy for a successful mobile app implementation. Our team of consultants is available throughout the process to offer expert guidance so your project doesn’t run into trouble.
                                    </p>
                                </div>

                                <div className="mb-6">
                                    <h3 className="title text-lg font-semibold text-left">
 Mobile App Design
                                    </h3>
                                    <p className="text-gray-600 text-left">
                                        The core focus of our mobile app development lies in offering a better user experience. Our expert mobile app developers are skilled in creating user-centric interface design for a seamless and engaging experience. Through prototyping and wireframing, we transform concepts into functional designs. We go above and beyond to make sure the final product exceeds user expectations. We offer custom app visuals that reflect your brand identity for a memorable user experience.
                                    </p>
                                </div>
                            </div>

                            <div className="flex flex-col " data-aos="fade-up" data-aos-duration="1500">
                                <div className="mb-6">
                                    <h3 className="title text-lg font-semibold text-left">

                                        Mobile App Development
                                    </h3>
                                    <p className="text-gray-600 text-left">
                                        Get ready to join the most amazing mobile app development journey with our professionals who specialize in full-stack development for iOS and Android platforms. We follow an agile methodology to maintain adaptability throughout the app development process. We rigorously follow quality assurance and codes. We test and refine your app to deliver a high-performance end product and seamless experience for your users.
                                    </p>
                                </div>

                                <div className="mb-6">
                                    <h3 className="title text-lg font-semibold text-left">
 Mobile App Integration
                                    </h3>
                                    <p className="text-gray-600 text-left">
                                        We can effortlessly integrate the developed mobile apps with third-party APIs and services through our specialized integration services. We guarantee seamless sync of data between mobile apps and backend systems for better real-time updates and streamlined processes. In addition, we provide regular support for better app updates and integrations so your app stays up-to-date with the latest trends and technologies.
                                    </p>
                                </div>
                            </div>

                            <div className="flex justify-start" data-aos="fade-up" data-aos-duration="1500">
                                <img
                                    src={Icons.mobiImg2}
                                    className="w-full object-cover"
                                    alt='Mobile App Integration'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ProcessSection />
        </div>
    )
}

export default MobileDevelopment