import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Icons } from '../assets/imageIndex';

const ClientsSection = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  const techItems = [
    { src: Icons.blockchainTechnology, alt: "Blockchain", title: "Blockchain" },
    { src: Icons.android, alt: "Android", title: "Android" },
    { src: Icons.physics, alt: "ReactJS", title: "ReactJS" },
    { src: Icons.wordpress, alt: "WordPress", title: "WordPress" },
    { src: Icons.magento, alt: "Magento", title: "Magento" },
    { src: Icons.python, alt: "Python", title: "Python" },
    { src: Icons.programming, alt: "Artificial Intelligence", title: "AI" },
    { src: Icons.document, alt: "VueJS", title: "VueJS" },
    { src: Icons.nodeJs, alt: "NodeJS", title: "NodeJS" },
    { src: Icons.php, alt: "PHP", title: "PHP" },
  ];

  return (
    <section className="clients section-padding py-16">
      <div className="full-width mx-auto">
        <div className="mb-12 text-center">
          <h1 className="text-4xl font-bold">Our Technologies</h1>
          <div className="w-16 border-t-2 border-gray-300 mx-auto mt-4"></div>
        </div>
        <div className="col-md-12">
          <Slider {...settings}>
            {techItems.map((tech, index) => (
              <div key={index} className="clients-logo flex flex-col items-center justify-center">
                <img className="w-32 h-32 md:w-20 md:h-20 mb-2" src={tech.src} alt={tech.alt} />
                <h5 className="text-gray-700 my-2">{tech.title}</h5>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default ClientsSection;
