import React from 'react';
import { Icons } from '../assets/imageIndex';
import ProcessSection from './ProcessSection';

const ECommerceSolutions = () => {
    return (
        <div>
            <section
                className="relative px-4 md:px-0 team bg-cover bg-center section-padding bg-opacity-100 py-16 "
                style={{ backgroundImage: `url(${Icons.stepImg})` }}
            >
                <div className="full-width mx-auto">
                    <div className="mb-8 text-center">
                        <h1 className="text-4xl font-bold">E-Commerce Solutions</h1>
                        <div className="border-t-2 border-gray-300 w-16 mx-auto mt-4"></div>
                    </div>

                    <p className="text-gray-600 text-left">
                        At Covantis Sarvagis Technologies, we offer cutting-edge e-commerce solutions designed to enhance your online presence and drive sales growth. From developing user-friendly e-commerce platforms to integrating secure payment gateways, we provide end-to-end solutions for businesses aiming to succeed in the digital marketplace.
                    </p>
                </div>
            </section>

            <section className="py-16 px-4 md:px-0 bg-gray-50">
                <div className="container mx-auto">
                    <div className="text-center mb-10" data-aos="fade-up" data-aos-duration="1500">
                        <h2 className="text-3xl font-semibold">E-Commerce Solutions Tailored for Your Business</h2>
                        <p className="mt-4 text-gray-700">
                            Our e-commerce solutions are designed to meet the unique needs of your business, ensuring a seamless shopping experience for your customers.
                        </p>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Custom E-Commerce Development</h5>
                            <p className="mt-2 text-gray-600">
                                We create custom e-commerce platforms tailored to your specific business requirements, ensuring a user-friendly experience for your customers.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Secure Payment Integration</h5>
                            <p className="mt-2 text-gray-600">
                                Our solutions include the integration of secure payment gateways, ensuring a smooth and secure transaction process for your customers.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Mobile Commerce Solutions</h5>
                            <p className="mt-2 text-gray-600">
                                We build mobile-optimized e-commerce platforms, providing a seamless shopping experience for users on smartphones and tablets.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Product Management Systems</h5>
                            <p className="mt-2 text-gray-600">
                                Our product management systems allow you to easily manage inventory, track sales, and optimize product listings across multiple channels.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">SEO & Digital Marketing</h5>
                            <p className="mt-2 text-gray-600">
                                We offer SEO and digital marketing solutions to drive traffic to your e-commerce site, increase sales, and boost your online visibility.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Analytics & Reporting</h5>
                            <p className="mt-2 text-gray-600">
                                Our e-commerce solutions include advanced analytics and reporting tools, allowing you to monitor performance and make informed business decisions.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <ProcessSection />
        </div>
    );
}

export default ECommerceSolutions;
