import React from 'react';
import { useParams } from 'react-router-dom';
import TitleHeader from '../component/TitleHeader';
import UIDevelopment from '../component/UIDevelopment';
import MobileDevelopment from '../component/MobileDevelopment';
import WebDevelopment from '../component/WebDevelopment';
import DataAnalystic from '../component/DataAnalystic';
import CustomSoftware from '../component/CustomSoftware';
import SocialNetworkDevelopment from '../component/SocialNetworkDevelopment';
import AIDevelopment from '../component/AIDevelopment';
import BusinessIntelligence from '../component/BusinessIntelligence';
import CloudServices from '../component/CloudServices';
import ReactNative from '../component/ReactNative';
import AndroidDevelopment from '../component/Android';
import RubyOnRailsDevelopment from '../component/RubyOnRailsDevelopment';
import FlutterDevelopment from '../component/FlutterDevelopment';
import JavaDevelopment from '../component/JavaDevelopment';
import MERNStackDevelopment from '../component/MERNStackDevelopment';
import NodeJsDevelopment from '../component/NodeJsDevelopment';
import ReactJsDevelopment from '../component/ReactJsDevelopment';
import BlockchainDevelopment from '../component/BlockchainDevelopment';
import AngularDevelopment from '../component/AngularDevelopment';
import WordPressDevelopment from '../component/WordPressDevelopment';
import MagentoDevelopment from '../component/MagentoDevelopment';
import PythonDevelopment from '../component/PythonDevelopment';
import ArtificialIntelligenceDevelopment from '../component/ArtificialIntelligenceDevelopment';
import ECommerceSolutions from '../component/ECommerceSolutions.jsx'
import ITConsulting from '../component/ITConsulting';
import CyberSecurityServices from '../component/CyberSecurityServices';
import DevOpsServices from '../component/DevOpsServices';
import DigitalMarketingServices from '../component/DigitalMarketingServices';


const componentsMap = {
    'ui-ux-design': { title: 'UI/UX Design', component: <UIDevelopment /> },
    'mobile-app-development': { title: 'Mobile App Development', component: <MobileDevelopment /> },
    'web-development': { title: 'Web Development', component: <WebDevelopment /> },
    'data-analytics': { title: 'Data Analytics', component: <DataAnalystic /> },
    'custom-software-development': { title: 'Custom Software Development', component: <CustomSoftware /> },
    'social-network-development': { title: 'Social Network Development', component: <SocialNetworkDevelopment /> },
    'ai-development': { title: 'AI Development', component: <AIDevelopment /> },
    'business-intelligence': { title: 'Business Intelligence', component: <BusinessIntelligence /> },
    'cloud-services': { title: 'Cloud Services', component: <CloudServices /> },
    'React Native': { title: 'React Native', component: <ReactNative /> },
    'Android': { title: 'Android', component: <AndroidDevelopment /> },
    'Ruby on Rails': { title: 'Ruby on Rails', component: <RubyOnRailsDevelopment /> },
    'Flutter': { title: 'Flutter', component: <FlutterDevelopment /> },
    'Java': { title: 'Java', component: <JavaDevelopment /> },
    'MERN Stack': { title: 'MERN Stack', component: <MERNStackDevelopment /> },
    'Node Js': { title: 'Node Js', component: <NodeJsDevelopment /> },
    'React Js': { title: 'React Js', component: <ReactJsDevelopment /> },
    'Blockchain': { title: 'Blockchain', component: <BlockchainDevelopment /> },
    'Angular': { title: 'Angular', component: <AngularDevelopment /> },
    'WordPress': { title: 'WordPress', component: <WordPressDevelopment /> },
    'Magento': { title: 'Magento', component: <MagentoDevelopment /> },
    'Python': { title: 'Python', component: <PythonDevelopment /> },
    'Artificial Intelligence': { title: 'Artificial Intelligence', component: <ArtificialIntelligenceDevelopment /> },
    'e-commerce-solutions': { title: 'E-Commerce Solutions', component: <ECommerceSolutions /> },
    'it-consulting': { title: 'IT Consulting', component: <ITConsulting /> },
    'cybersecurity-services': { title: 'Cybersecurity Services', component: <CyberSecurityServices /> },
    'devops-services': { title: 'Devops Services', component: <DevOpsServices /> },
    'digital-marketing-services': { title: 'Digital Marketing Services', component: <DigitalMarketingServices /> },
};

const ServiceDetail = () => {
    const { serviceName } = useParams();
    const service = componentsMap[serviceName];

    return (
        <div>
            {service && <TitleHeader title={service.title} />}
            {service?.component}
        </div>
    );
};

export default ServiceDetail;
