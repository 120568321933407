import React from 'react';
import { Icons } from '../assets/imageIndex';
import ExpertDetail from './ExpertDetail';

const ReactNative = () => {
    return (
        <div>
            <section
                className="relative px-4 md:px-0 team bg-cover bg-center section-padding bg-opacity-100 py-16 "
                style={{ backgroundImage: `url(${Icons.stepImg})` }}
            >
                <div className="full-width mx-auto">
                    <div className="mb-8 text-center">
                        <h1 className="text-4xl font-bold">React Native Development Services</h1>
                        <div className="border-t-2 border-gray-300 w-16 mx-auto mt-4"></div>
                    </div>

                    <p className="text-gray-600 text-left">
                    Hire knowledgeable React Native app developers from Covantis Sarvagis to incorporate any popular React Native technology you depend on, like VSCode, Expo, Enzyme, ESLint, Flow, and Reactotron. We make use of sophisticated frameworks like Nativebase and Ignite, as well as MobX, Animatable, and React Navigation libraries.

To align the project with your varied business requirements, we take a customer-centric approach. You will also have a flexible model of engagement when you hire top React Native App developers from a React Native development company. The fundamental reason we are regarded as the top React Native app development company is our consistency in doing thorough quality checks at each stage of the creation of hybrid apps. </p>
                </div>
            </section>

            <section className="py-16 px-4 md:px-0 bg-gray-50">
                <div className="full-width mx-auto">
                    <div className="text-center mb-10" data-aos="fade-up" data-aos-duration="1500">
                        <h2 className="text-3xl font-semibold">Why Hire React Native Developers From Covantis Sarvagis</h2>
                        <p className="mt-4 text-gray-700">
                        Employ Dedicated React Native developers from Covantis Sarvagis since they are well-versed in their methods and know how to apply them effectively in line with the intended company concept. Our React Native basis is already well known to agile methods.
                        </p>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Quick Development</h5>
                            <p className="mt-2 text-gray-600">
                            By using new methods and techniques, our developers work together to achieve maximum results within the specified timeframe.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Better Performance</h5>
                            <p className="mt-2 text-gray-600">
                            With Covantis Sarvagis, you get the finest React Native developers who have the required experience and skills to meet your requirements.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Global Delivery Agent</h5>
                            <p className="mt-2 text-gray-600">
                            Our React Native development services are provided on a global scale, helping our customers achieve their goals no matter where they are located.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Strong Community
                            </h5>
                            <p className="mt-2 text-gray-600">
                            React Native is one of the best frameworks, so hiring a React Native developer with Covantis Sarvagis will give you the needed returns.


                            </p>
                        </div>
                        </div>
                </div>
            </section>
            <ExpertDetail title={'React Native'} />
        </div>
    );
}

export default ReactNative;
