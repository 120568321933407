import React, { useState } from 'react'
import TitleHeader from '../component/TitleHeader'
import LetsTalk from '../component/LetsTalk'
import AboutSection from '../component/AboutSection'
import { FaHome, FaRegEye, FaBullseye } from 'react-icons/fa';
import { Icons } from '../assets/imageIndex';
import { features } from '../constant';
import ClientsSection from '../component/ClientsSection';

const AboutScreen = () => {
  const [activeTab, setActiveTab] = useState('mission');

  const renderTabContent = () => {
    switch (activeTab) {
      case 'mission':
        return (
          <div>
            <h3 className="title text-2xl font-medium mb-4">Empowering Innovation</h3>
            <p className="mb-4">
              Our mission is to empower businesses by providing innovative digital solutions that drive
              growth and success. We aim to transform visions into reality through cutting-edge web
              design, seamless user experiences, and powerful technology integrations.
            </p>
            <p>
              We believe in creating designs that not only capture attention but also provide a meaningful
              and valuable experience for users.
            </p>
          </div>
        );
      case 'vision':
        return (
          <div>
            <h3 className="title text-2xl font-medium mb-4">Leading the Digital Future</h3>
            <p className="mb-4">
              Our vision is to become a global leader in the digital space by continually pushing the
              boundaries of design, technology, and creativity. We envision a future where businesses
              leverage digital platforms to reach new heights of success and where users are inspired by
              exceptional digital experiences.
            </p>
            <p>
              We are committed to staying ahead of industry trends, providing our clients with the tools
              they need to thrive in the ever-evolving digital landscape.
            </p>
          </div>
        );
      case 'goal':
        return (
          <div>
            <h3 className="title text-2xl font-medium mb-4">Creating Value for All</h3>
            <p className="mb-4">
              Our goal is to create long-lasting value for our clients by delivering projects that meet
              their business objectives while ensuring a positive impact on their customers. We aim to
              foster relationships built on trust, transparency, and mutual growth.
            </p>
            <p>
              We strive for excellence in every project, from local businesses to global enterprises, by
              focusing on user satisfaction, innovative designs, and efficient solutions.
            </p>
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <div>
      <TitleHeader title={'About Us'} />
      <AboutSection />
      <section className="about-section-5 py-16">
        <div className="full-width mx-auto">
          <div className="flex gap-12">
            <div className="about-content-5 w-full mx-8 md:mx-0">
              <div className="section-heading mb-6">
                <h2 className="section-title text-4xl font-bold">
                  Our Main Goal is to Satisfy Local & Global Clients
                </h2>
              </div>
              <div className="about-tab">
                <nav>
                  <div className="nav-tabs flex  mb-4" id="nav-tab" role="tablist">
                    <button
                      className={`nav-link py-2 w-full  ${activeTab === 'mission' ? 'border-b-2 border-orange-500 text-orange-600' : 'border-b-2 border-gray-500 text-gray-500'}`}
                      onClick={() => setActiveTab('mission')}
                      type="button"
                      role="tab"
                    >
                      <FaHome className="inline-block mr-2" />
                      Our Mission
                    </button>
                    <button
                      className={`nav-link w-full py-2  ${activeTab === 'vision' ? 'border-b-2 border-orange-500 text-orange-600' : 'border-b-2 border-gray-500 text-gray-500'}`}
                      onClick={() => setActiveTab('vision')}
                      type="button"
                      role="tab"
                    >
                      <FaRegEye className="inline-block mr-2" />
                      Our Vision
                    </button>
                    <button
                      className={`nav-link w-full py-2  ${activeTab === 'goal' ? 'border-b-2 border-orange-500 text-orange-600' : 'border-b-2 border-gray-500 text-gray-500'}`}
                      onClick={() => setActiveTab('goal')}
                      type="button"
                      role="tab"
                    >
                      <FaBullseye className="inline-block mr-2" />
                      Our Goal
                    </button>
                  </div>
                </nav>
                <div className="tab-content">
                  {renderTabContent()}
                </div>
              </div>
            </div>
            <div className="about-img-wrap-5 relative hidden md:inline overflow-hidden w-2/3">
              <div data-aos="fade-left" data-aos-duration="1500">
                <div className=" inset-0 transform translate-x-20 scale-125 ">
                  <img
                    className="img-2 block w-full h-full object-cover"
                    src={Icons.latestpromoImg2}
                    alt="Professional Team"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <CounterSection /> */}
      <section
        className="relative team bg-cover bg-center section-padding bg-opacity-100 py-16"
        style={{ backgroundImage: `url(${Icons.stepImg})` }}
      >
  <div className="full-width mx-auto">
    <h2 className="text-4xl font-bold mb-12">Why Work With Us</h2>
    {features.map((feature, index) => (
      <div key={index} data-aos="fade-up" data-aos-duration="1000">
        <div className=" border-b border-gray-200 py-6 flex items-center">
          <div className="w-1/12 justify-center hidden md:inline">
            <img
              src={feature.icon}
              alt={feature.title}
              className="w-16 mil-icon-frame mb-6 rotate-on-hover "
            />
          </div>
          <div className="w-1/3 hidden md:inline">
            <h4 className="text-xl text-center font-medium mb-6">{feature.title}</h4>
          </div>
          <div className="w-full mx-8 md:mx-0 md:w-10/12">
            <img
              src={feature.icon}
              alt={feature.title}
              className="w-16 mil-icon-frame mb-6 inline-block md:hidden rotate-on-hover"
            />
            <br />
            <h4 className="text-xl text-center font-medium mb-6 inline-block md:hidden">{feature.title}</h4>
            <p className="text-gray-600 text-left">{feature.description}</p>
          </div>
        </div>
      </div>
    ))}
  </div>
</section>

      <ClientsSection />
      <LetsTalk />
    </div>
  )
}

export default AboutScreen