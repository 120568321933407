import React, { useState } from 'react';
import TitleHeader from '../component/TitleHeader';
import { IoCallOutline } from "react-icons/io5";
import { FaRegEnvelope } from "react-icons/fa";
import { GrMapLocation } from "react-icons/gr";
import LetsTalk from '../component/LetsTalk';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';
import { FaInstagram, FaWhatsapp, FaYoutube } from 'react-icons/fa';
import { FaSquareTwitter } from "react-icons/fa6";
import { technologiesData } from '../constant';

const ContactScreen = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
        skypId: '',
        budget: '',
        technologies: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const isValidEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    const isValidPhone = (phone) => {
        const regex = /^[0-9]+$/;
        return regex.test(phone);
    };

    // emailjs Api Call
    const sendEmail = (e) => {
        e.preventDefault();

        if (!isValidEmail(formData.email)) {
            toast.warning("Please enter a valid email address.");
            return;
        }

        if (!isValidPhone(formData.phone)) {
            toast.warning("Please enter a valid phone number");
            return;
        }

        const paramsVal = {
            from_name: formData.name,
            from_email: formData.email,
            form_number: formData.phone,
            message: formData.message,
            skypId: formData.skypId,
            technologies: formData.technologies,
            budget: formData.budget
        };

        emailjs.send('service_ehoxz73', 'template_d6ggx4g', paramsVal, 'jP0Qk3uV_BTVu0-iu')
            .then(
                () => {
                    toast.success("Thank you, we will contact you soon.");
                    setFormData({ name: '', email: '', budget: '', technologies: '', phone: '', message: '', skypId: '' });
                },
                (error) => {
                    console.error('Failed to send mail:', error.text);
                    toast.warning("Failed, try again.");
                }
            );
    };

    return (
        <div>
            <TitleHeader title={'Contact Us'} />
            <section className="contact section-padding py-16">
                <div className="full-width mx-auto px-4 md:px-8 lg:px-8">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        {/* Left Column: Central Office Info */}
                        <div data-aos="fade-up" data-aos-duration="1000" >
                            <h5 className="text-xl text-left font-bold mb-0">Let’s working together</h5>
                            <p className="mb-6 text-left">
                            Thank you for your interest in Covantis Sarvagis Technologies. We're excited <br/>to hear from you and discuss...                            </p>
                            <div className="con flex items-center mb-4">
                                <div className="icon mr-4 text-2xl text-blue-500">
                                    <IoCallOutline className='inline' />
                                </div>
                                <div className="con-content">
                                    <p className="text"><a href="tel:1234567890" className="text-gray-500">1234567890</a></p>
                                </div>
                            </div>
                            <div className="con flex items-center mb-4">
                                <div className="icon mr-4 text-2xl text-blue-500">
                                    <FaRegEnvelope className='inline' />
                                </div>
                                <div className="con-content">
                                    <p className="text"><a href="mailto:hello@yourmail.com" className="text-gray-500">hello@yourmail.com</a></p>
                                </div>
                            </div>
                            <div className="con flex items-center mb-4">
                                <div className="icon mr-4 text-2xl text-gray-500 justify-center">
                                    <GrMapLocation className='inline' />
                                </div>
                                <div className="con-content">
                                    <p className="text text-gray-500">
                                        B-178/61, 2, New Ashok Nagar, New Delhi, Delhi, 110096
                                    </p>
                                </div>
                            </div>
                            <h5 className="text-xl text-left font-bold mb-0">Social Links</h5>
                            <div className="flex justify-left space-x-4">
                                <button className="link-class text-gray-400 hover:text-orange-500"><FaInstagram size={24} /></button>
                                <button className="link-class text-gray-400 hover:text-orange-500"><FaWhatsapp size={24} /></button>
                                <button className="link-class text-gray-400 hover:text-orange-500"><FaYoutube size={24} /></button>
                                <button className="link-class text-gray-400 hover:text-orange-500"><FaSquareTwitter size={24} /></button>
                            </div>
                        </div>

                        {/* Right Column: Contact Form */}
                        <div data-aos="fade-up" data-aos-duration="1000">
                            <h5 className="text-xl font-bold text-left mb-8">Get in touch!</h5>
                            <form onSubmit={sendEmail} className="contact__form">
                                <div className="row">
                                    <div className="col-md-12 form-group mb-4">
                                        <input
                                            name="name"
                                            type="text"
                                            placeholder="Name *"
                                            value={formData.name}
                                            onChange={handleInputChange}
                                            required
                                            className="w-full p-3 text-black border border-gray-300 rounded"
                                        />
                                    </div>
                                    <div className="col-md-6 form-group mb-4">
                                        <input
                                            name="email"
                                            type="email"
                                            placeholder="Email Address *"
                                            value={formData.email}
                                            onChange={handleInputChange}
                                            required
                                            className="w-full p-3 text-black border border-gray-300 rounded"
                                        />
                                    </div>
                                    <div className="col-md-6 form-group mb-4">
                                        <input
                                            name="phone"
                                            type="text"
                                            placeholder="Phone *"
                                            value={formData.phone}
                                            onChange={handleInputChange}
                                            required
                                            className="w-full p-3 text-black border border-gray-300 rounded"
                                        />
                                    </div>
                                    <div className="col-md-12 form-group mb-4">
                                        <input
                                            name="skypId"
                                            type="text"
                                            placeholder="Skype Id"
                                            value={formData.skypId}
                                            onChange={handleInputChange}
                                            className="w-full p-3 border text-black border-gray-300 rounded"
                                        />
                                    </div>
                                    <div className="col-md-12 form-group mb-4">
                                        <select
                                            name="technologies"
                                            required
                                            value={formData.technologies}
                                            onChange={handleInputChange}
                                            className="w-full p-3 text-black border border-gray-300 rounded"
                                        >
                                            <option value="">Select Technology</option>
                                            {technologiesData?.map((v) =>
                                                <option value={v}>{v}</option>)}
                                        </select>
                                    </div>
                                    <div className="col-md-12 form-group mb-4">
                                        <select
                                            required
                                            name="budget"
                                            value={formData.budget}
                                            onChange={handleInputChange}
                                            className="w-full p-3 text-black border border-gray-300 rounded"
                                        >
                                            <option value="">Select Budget</option>
                                            <option value="Less than 5000$">Less than 5000$</option>
                                            <option value="$5000-$10000">$5000-$10000</option>
                                            <option value="$10000-$50000">$10000-$50000</option>
                                            <option value="$50000+">$50000+</option>
                                        </select>
                                    </div>
                                    <div className="col-md-12 form-group mb-4">
                                        <textarea
                                            name="message"
                                            cols="30"
                                            rows="4"
                                            placeholder="Project Description *"
                                            value={formData.message}
                                            onChange={handleInputChange}
                                            required
                                            className="w-full p-3 border text-black border-gray-300 rounded"
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <button type="submit" className="bg-orange-500 px-8 py-2 text-white rounded hover:bg-orange-600">Submit</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <LetsTalk />
        </div>
    );
};

export default ContactScreen;
