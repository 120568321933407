import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Icons } from '../../assets/imageIndex';
import { FaBars } from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";
import Modal from 'react-modal';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';
import { technologiesData } from '../../constant';

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    skypId: '',
    budget: '',
    technologies: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const isValidEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const isValidPhone = (phone) => {
    const regex = /^[0-9]+$/;
    return regex.test(phone);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (!isValidEmail(formData.email)) {
      toast.warning("Please enter a valid email address.");
      return;
    }

    if (!isValidPhone(formData.phone)) {
      toast.warning("Please enter a valid phone number");
      return;
    }

    const paramsVal = {
      from_name: formData.name,
      from_email: formData.email,
      form_number: formData.phone,
      message: formData.message,
      skypId: formData.skypId,
      technologies: formData.technologies,
      budget: formData.budget
    };

    emailjs.send('service_ehoxz73', 'template_d6ggx4g', paramsVal, 'jP0Qk3uV_BTVu0-iu')
      .then(
        () => {
          setIsModalOpen(false);
          toast.success("Thank you, we will contact you soon.");
          setFormData({ name: '', email: '', budget: '', technologies: '', phone: '', message: '', skypId: '' });
        },
        (error) => {
          console.error('Failed to send mail:', error.text);
          toast.warning("Failed, try again.");
        }
      );
  };

  const toggleMobileMenu = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setIsMobileMenuOpen(false);
  };

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    setIsMobileMenuOpen(false);
    setFormData({ name: '', email: '', budget: '', technologies: '', phone: '', message: '', skypId: '' });
  };

  return (
    <div className="min-h-full sticky top-0 z-50">
      <nav className="bg-white shadow-md">
        <div className="full-width mx-auto flex justify-between items-center py-2 px-4 lg:px-0">
          {/* Logo */}
          <div className="logo-wrapper">
            <Link onClick={handleScrollToTop} className="logo " to="/">
              <img src={Icons.logoLight} className="w-40" alt="Logo" />
            </Link>
          </div>

          {/* Navbar Toggler for Mobile */}
          <div className="lg:hidden">
            <button
              className="text-gray-500 focus:outline-none"
              aria-label="Toggle navigation"
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            >
              {isMobileMenuOpen ? <IoCloseSharp size={24} /> : <FaBars size={24} />}
            </button>
          </div>

          {/* Navbar Links for Desktop */}
          <div className="hidden lg:flex space-x-6 sticky top-0 items-center">
            <ul className="flex space-x-4">
              <li className="nav-item">
                <Link onClick={handleScrollToTop} className="nav-link text-black hover:text-orange-600" to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link onClick={handleScrollToTop} className="nav-link text-black hover:text-orange-600" to="/about">
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link onClick={handleScrollToTop} className="nav-link text-black hover:text-orange-600" to="/services">
                  Services
                </Link>
              </li>
              <li className="nav-item">
                <Link onClick={handleScrollToTop} className="nav-link text-black hover:text-orange-600" to="/contact">
                  Contact Us
                </Link>
              </li>
            </ul>

            {/* Right Side of Navbar for Desktop */}
            <div className="navbar-right flex text-center items-center space-x-4">
              <button
                onClick={toggleModal} // Open modal on click
                className="bg-orange-500 px-4 py-2 my-2 text-white hover:bg-orange-600 rounded-full"
              >
                Get a Request
              </button>
            </div>
          </div>
        </div>

        {/* Mobile Menu */}
        {isMobileMenuOpen && (
          <div className="absolute w-full lg:hidden text-left  bg-white px-8 py-4 ">
            <ul className="flex flex-col space-y-2">
              <li className="nav-item">
                <Link
                  className="nav-link text-gray-700 hover:text-orange-600 block"
                  to="/"
                  onClick={toggleMobileMenu}
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link text-gray-700 hover:text-orange-600 block"
                  to="/about"
                  onClick={toggleMobileMenu}
                >
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link text-gray-700 hover:text-orange-600 block"
                  to="/services"
                  onClick={toggleMobileMenu}
                >
                  Services
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link text-gray-700 hover:text-orange-600 block"
                  to="/contact"
                  onClick={toggleMobileMenu}
                >
                  Contact Us
                </Link>
              </li>
            </ul>

            {/* Right Side of Navbar for Mobile */}
            <div className="justify-center mt-4" style={{ marginLeft: -10 }}>
              <button
                onClick={toggleModal} // Open modal on mobile click
                className="bg-orange-500 px-4 py-2 text-white hover:bg-orange-600 rounded-full"
              >
                Get a Request
              </button>
            </div>
          </div>
        )}

        {/* Modal */}
        <Modal
          isOpen={isModalOpen}
          className={'bg-white z-50 p-6 rounded-lg shadow-lg w-full md:w-1/3 md:mx-auto mt-20 self-center max-h-screen overflow-auto'}
        >
          <div data-aos="zoom-in" className="z-50">
            <div className='flex items-center justify-between mb-4'>
              <div></div>
              <h5 className="text-xl font-bold text-center">Get In Touch!</h5>
              <button onClick={toggleModal}>
                <IoCloseSharp size={24} className='text-black' />
              </button>
            </div>
            <form onSubmit={sendEmail} className="contact__form">
              <div className="row">
                <div className="col-md-12 form-group mb-4">
                  <input
                    name="name"
                    type="text"
                    placeholder="Name *"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                    className="w-full p-2 text-black border border-gray-300 rounded"
                  />
                </div>
                <div className="col-md-6 form-group mb-4">
                  <input
                    name="email"
                    type="email"
                    placeholder="Email Address *"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                    className="w-full p-2 text-black border border-gray-300 rounded"
                  />
                </div>
                <div className="col-md-6 form-group mb-4">
                  <input
                    name="phone"
                    type="text"
                    placeholder="Phone *"
                    value={formData.phone}
                    onChange={handleInputChange}
                    required
                    className="w-full p-2 text-black border border-gray-300 rounded"
                  />
                </div>
                <div className="col-md-12 form-group mb-4">
                  <input
                    name="skypId"
                    type="text"
                    placeholder="Skype Id"
                    value={formData.skypId}
                    onChange={handleInputChange}
                    className="w-full p-2 border text-black border-gray-300 rounded"
                  />
                </div>
                <div className="col-md-12 form-group mb-4">
                  <select
                    name="technologies"
                    required
                    value={formData.technologies}
                    onChange={handleInputChange}
                    className="w-full p-2 text-black border border-gray-300 rounded"
                  >
                    <option value="">Select Technology</option>
                    {technologiesData?.map((v) =>
                      <option value={v}>{v}</option>)}
                  </select>
                </div>
                <div className="col-md-12 form-group mb-4">
                  <select
                    name="budget"
                    required
                    value={formData.budget}
                    onChange={handleInputChange}
                    className="w-full p-2 text-black border border-gray-300 rounded"
                  >
                    <option value="">Select Budget</option>
                    <option value="Less than 5000$">Less than 5000$</option>
                    <option value="$5000-$10000">$5000-$10000</option>
                    <option value="$10000-$50000">$10000-$50000</option>
                    <option value="$50000+">$50000+</option>
                  </select>
                </div>
                <div className="col-md-12 form-group mb-4">
                  <textarea
                    name="message"
                    cols="30"
                    rows="2"
                    placeholder="Project Description *"
                    value={formData.message}
                    onChange={handleInputChange}
                    required
                    className="w-full p-2 border text-black border-gray-300 rounded"
                  />
                </div>
                <div className="col-md-12 text-center">
                  <button type="submit" className="bg-orange-500 px-8 py-2 text-white rounded hover:bg-orange-600">Submit</button>
                </div>
              </div>
            </form>
          </div>
        </Modal>

      </nav>
    </div>
  );
};

export default Header;

