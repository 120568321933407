import React from 'react'
import Carousel from '../component/Banner'
import ServicesSection from '../component/ServicesSection'
import WorksSection from '../component/WorksSection'
import ClientsSection from '../component/ClientsSection'
import CounterSection from '../component/CounterSection'
import AboutSection from '../component/AboutSection'
import ProcessSection from '../component/ProcessSection'
import LetsTalk from '../component/LetsTalk'
import { Link } from 'react-router-dom'
import { FaArrowRight } from 'react-icons/fa'

const HomeScreen = () => {
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  
  return (
    <div>
      <Carousel />
      <AboutSection />
      <br />
      <ServicesSection title={'home'} />
      <br />
      <Link onClick={handleScrollToTop}
        to="/services"
        className="bg-orange-500 px-4 py-2 justify-center-center text-white hover:none rounded-full"
      >
        View All <FaArrowRight className='mb-1' />
      </Link>
      <ProcessSection />
      <WorksSection />
      <CounterSection />
      <ClientsSection />
      <LetsTalk />
    </div>
  )
}

export default HomeScreen