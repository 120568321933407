import React from 'react';
import { Icons } from '../assets/imageIndex';
import ProcessSection from './ProcessSection';

const DevOpsServices = () => {
    return (
        <div>
            <section
                className="relative px-4 md:px-0 team bg-cover bg-center section-padding bg-opacity-100 py-16"
                style={{ backgroundImage: `url(${Icons.stepImg})` }}
            >
                <div className="full-width mx-auto">
                    <div className="mb-8 text-center">
                        <h1 className="text-4xl font-bold">DevOps Services</h1>
                        <div className="border-t-2 border-gray-300 w-16 mx-auto mt-4"></div>
                    </div>

                    <p className="text-gray-600 text-left">
                        At Covantis Sarvagis Technologies, we offer comprehensive DevOps services that streamline your development and operations processes. By fostering a culture of collaboration between development and IT operations, we help your business accelerate delivery, improve quality, and enhance customer satisfaction.
                    </p>
                </div>
            </section>

            <section className="py-16 px-4 md:px-0 bg-gray-50">
                <div className="container mx-auto">
                    <div className="text-center mb-10" data-aos="fade-up" data-aos-duration="1500">
                        <h2 className="text-3xl font-semibold">Transform Your Development and Operations</h2>
                        <p className="mt-4 text-gray-700">
                            Our DevOps services are designed to improve collaboration, increase deployment frequency, and ensure high-quality software delivery through continuous integration and continuous delivery (CI/CD) practices.
                        </p>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Continuous Integration & Delivery</h5>
                            <p className="mt-2 text-gray-600">
                                We implement CI/CD pipelines that automate the process of integrating code changes and delivering them to production, ensuring faster and more reliable releases.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Infrastructure as Code (IaC)</h5>
                            <p className="mt-2 text-gray-600">
                                Our IaC solutions enable you to manage and provision infrastructure through code, improving consistency, scalability, and efficiency in your environments.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Monitoring and Logging</h5>
                            <p className="mt-2 text-gray-600">
                                We set up monitoring and logging tools to gain real-time insights into application performance, enabling proactive issue resolution and optimization.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Automated Testing</h5>
                            <p className="mt-2 text-gray-600">
                                Our automated testing services ensure that your software is thoroughly tested at every stage of the development lifecycle, reducing defects and improving quality.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Containerization and Orchestration</h5>
                            <p className="mt-2 text-gray-600">
                                We leverage containerization technologies like Docker and orchestration tools like Kubernetes to enhance application deployment and management.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Cloud Solutions</h5>
                            <p className="mt-2 text-gray-600">
                                Our cloud solutions help you migrate to the cloud, optimize cloud resources, and implement best practices for cloud-native application development.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <ProcessSection />
        </div>
    );
}

export default DevOpsServices;
