import React from 'react'
import { Icons } from '../assets/imageIndex'
import ProcessSection from './ProcessSection'

const UIDevelopment = () => {
    return (
        <div>
            <section
                className="relative team px-4 md:px-0 bg-cover bg-center section-padding bg-opacity-100 py-16"
                style={{ backgroundImage: `url(${Icons.stepImg})` }}
            >
                <div className="full-width mx-auto">
                    <div className="mb-8 text-center">
                        <h1 className="text-4xl font-bold" data-splitting="">Creative UI/UX Development</h1>
                        <div className="border-t-2 border-gray-300 w-16 mx-auto mt-4"></div>
                    </div>

                    <p className="text-gray-600 text-left">Covantis Sarvagis Technologies , one of the leading UI UX design company , prioritizes your needs and works to turn your idea into a reality in the cutthroat marketplace. We comprehend your product requirements and think from the customer’s perspective to understand what will persuade them and how your product will appeal to the specific customer. To improve the user experience, our committed team works on your UI/UX and provides a timeless, understated, and appealing visual design.

                        A simple user interface helps you build a strong brand identity and inspires confidence among your audience. We are here to do everything in our power to make your website appear meaningful and visually appealing. Our goal is to bridge gaps between stakeholders and users by giving a new look and feel to web applications.</p>

                </div>
            </section>
            <section className="py-16 px-4 md:px-0 bg-gray-50">
                <div className="container mx-auto">
                    <div className="text-center mb-10" data-aos="fade-up" data-aos-duration="1500">
                        <h2 className="text-3xl font-semibold">UI/UX Design Services from Our Industry Experts</h2>
                        <p className="mt-4 text-gray-600">
                            We are a reputable UI/UX design services company that combines innovative strategies with unique designs to create the most amazing user experience.
                        </p>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                         <div className="bg-white p-6 shadow-md rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Information Architecture</h5>
                            <p className="mt-2 text-gray-600">
                                We take pride in building the information architecture, which rightly aligns with business goals, product usability, and user requirements. We go above and beyond to create intuitive navigation and structures. We conduct detailed competitor analysis and research to create clear strategies.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-md rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">User Research</h5>
                            <p className="mt-2 text-gray-600">
                                We use the correct research practices to understand your end users' mindset and expectations. Our team takes the help of qualitative insights to improve the overall visual impact of our designs, which eventually enhances user experience.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-md rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">UI/UX Design</h5>
                            <p className="mt-2 text-gray-600">
                                From wireframing to creating high-quality mockups, our experienced team of UI/UX designers does it all. We follow the right branding guidelines, animations, fonts, and colors to create unique user experiences that resonate with your audiences.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-md rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">UX Audit</h5>
                            <p className="mt-2 text-gray-600">
                                Besides designing, we also perform UX audits to actively evaluate your product’s user experience. We use performance metrics, qualitative analysis, and user testing to diagnose possible challenges. Following this, we provide appropriate solutions that can enhance user satisfaction.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-md rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Redesign</h5>
                            <p className="mt-2 text-gray-600">
                                Your app requires constant redesigning to stay ahead of the competition. With our advanced redesign services, we can put a new life into your product. Before redesigning, our team identifies areas of improvement and develops solutions that align with your brand.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-md rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Branding</h5>
                            <p className="mt-2 text-gray-600">
                                Through our technical expertise and creativity, we can help you represent your brand in the most amazing way. From ideation to implementation, our team does everything to create impactful designs that help you meet goals.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <ProcessSection />
        </div>
    )
}

export default UIDevelopment