import React from 'react';
import { Icons } from '../assets/imageIndex';
import ProcessSection from './ProcessSection';

const ITConsulting = () => {
    return (
        <div>
            <section
                className="relative px-4 md:px-0 team bg-cover bg-center section-padding bg-opacity-100 py-16 "
                style={{ backgroundImage: `url(${Icons.stepImg})` }}
            >
                <div className="full-width mx-auto">
                    <div className="mb-8 text-center">
                        <h1 className="text-4xl font-bold">IT Consulting Services</h1>
                        <div className="border-t-2 border-gray-300 w-16 mx-auto mt-4"></div>
                    </div>

                    <p className="text-gray-600 text-left">
                        At Covantis Sarvagis Technologies, we offer expert IT consulting services that empower businesses to optimize their technology infrastructure and improve operational efficiency. Our team of skilled consultants works closely with you to identify gaps, design strategies, and implement solutions that drive growth and innovation.
                    </p>
                </div>
            </section>

            <section className="py-16 px-4 md:px-0 bg-gray-50">
                <div className="container mx-auto">
                    <div className="text-center mb-10" data-aos="fade-up" data-aos-duration="1500">
                        <h2 className="text-3xl font-semibold">IT Consulting Services Tailored for Your Business</h2>
                        <p className="mt-4 text-gray-700">
                            Whether you're looking to modernize your IT infrastructure, migrate to the cloud, or develop a robust security strategy, we offer customized IT consulting services to help you achieve your business goals.
                        </p>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">IT Strategy & Planning</h5>
                            <p className="mt-2 text-gray-600">
                                We help you develop a comprehensive IT strategy that aligns with your business goals and ensures long-term success by leveraging the latest technologies.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Cloud Consulting</h5>
                            <p className="mt-2 text-gray-600">
                                Our cloud consulting services assist with cloud migration, optimization, and management, helping your business become more agile, scalable, and cost-effective.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">IT Infrastructure Assessment</h5>
                            <p className="mt-2 text-gray-600">
                                We conduct in-depth assessments of your current IT infrastructure to identify areas for improvement, ensuring that your systems are efficient and secure.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Cybersecurity Consulting</h5>
                            <p className="mt-2 text-gray-600">
                                Protect your business with our cybersecurity consulting services, which include risk assessments, security strategy development, and implementation of advanced protection measures.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Digital Transformation</h5>
                            <p className="mt-2 text-gray-600">
                                Embrace the future with our digital transformation services that help your business innovate, streamline processes, and adapt to changing market demands through technology.
                            </p>
                        </div>
                         <div className="bg-white p-6 shadow-lg rounded-lg" data-aos="fade-up" data-aos-duration="1500">
                            <h5 className="text-xl font-semibold">Technology Implementation</h5>
                            <p className="mt-2 text-gray-600">
                                Our team ensures seamless technology implementation by assisting with software integration, system upgrades, and ongoing support to meet your unique business requirements.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <ProcessSection />
        </div>
    );
}

export default ITConsulting;
