import React from 'react';
import { Icons } from '../assets/imageIndex';

const TitleHeader = ({ title }) => {
    return (
        <section
            className="banner-header h-72 section-padding bg-fixed bg-img bg-top content-center"
            style={{ backgroundImage: `url(${Icons.bannerImg})` }}
        >
            <div data-aos="fade-up" data-aos-duration="1500">
            <h1 className=" text-4xl text-orange-600 font-bold">{title}</h1>
            </div>
        </section>
    );
};

export default TitleHeader;
